import { Injectable } from "@angular/core";
import { FormArray, UntypedFormBuilder, FormGroup, Validators } from "@angular/forms";
import { QuantityValidatorMax, ValidatorsService } from "./validators.service";
import { DocsGeneratorService } from "./docs-generator.service";
import { StorageUtils } from "app/shared/storage-utils";
import { SpinnerVisibilityService } from "ng-http-loader";
import { Rebate } from "app/shared/models/rebate";
import { DataStorageService } from "./data.service";
@Injectable({
    providedIn: "root",
})

export class GrouppedDocsGeneratorService {
    tvaList = [];
    constructor(
        private formBuilder: UntypedFormBuilder,
        private validatorsService: ValidatorsService,
        private docsGeneratorService: DocsGeneratorService,
        public spinnerService: SpinnerVisibilityService, private cacheStorageService: DataStorageService) {
        this.tvaList = StorageUtils.getTva();
    }

    getProductControls(products, originalProducts) {
        this.spinnerService.show();
        // todo: add newInvoice switch case
        const productDTOS = [];
        products.sort(function (a, b) {
            return a.order - b.order;
        });
        products.forEach((product, index) => {
            productDTOS.push(this.addProduct(product, originalProducts[index]));
        });
        setTimeout(() => {
            this.spinnerService.hide();
        }, 150);
        return productDTOS;
    }

    getRebatesControls(rebates) {
        this.spinnerService.show();
        // todo: add newInvoice switch case
        const rebatesDTOS = [];
        rebates.sort(function (a, b) {
            return a.order - b.order;
        });
        rebates.forEach(rebate => {
            rebatesDTOS.push(this.newRebate(rebate));
        });
        setTimeout(() => {
            this.spinnerService.hide();
        }, 150);
        return rebatesDTOS;
    }

    addProduct(product, originalProduct, newProduct?) {
        const prdct = this.formBuilder.group({
            productInLinkedDocumentId: this.formBuilder.control({
                value: product.productInLinkedDocumentId ?
                    product.productInLinkedDocumentId : product.id, disabled: true
            }),
            productId: this.formBuilder.control({ value: product.productId, disabled: true }),
            id: this.formBuilder.control({ value: newProduct ? null : product.id, disabled: true }),
            name: this.formBuilder.control({ value: product.name, disabled: newProduct ? false : true }, Validators.required),
            measureUnitId: this.formBuilder.control({ value: product.measureUnitId, disabled: true }, Validators.required),
            unitPrice: this.formBuilder.control({ value: this.docsGeneratorService.FormatAmountValue(product.unitPrice), disabled: true },
                Validators.compose([Validators.required,
                Validators.pattern("^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"),
                this.validatorsService.validateMinZero])),
            quantity: this.formBuilder.control(this.docsGeneratorService.formatQuantities(product.quantity, product.measureUnitId === 4),
                Validators.compose([Validators.required, Validators.pattern(
                    "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"),
                originalProduct.quantity ? QuantityValidatorMax(originalProduct.quantity) : null])),
            priceBeforeDiscount: this.formBuilder.control(
                {
                    value: this.docsGeneratorService.FormatAmountValue(
                        this.calculateProductPriceLite(
                            this.docsGeneratorService.FormatAmountValue(product.unitPrice),
                            this.docsGeneratorService.formatQuantities(product.quantity, product.measureUnitId === 4))
                    ), disabled: true
                },
                [Validators.compose([Validators.required, Validators.min(0), Validators.pattern(
                    "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"),]),]),
            preTaxPrice: this.formBuilder.control(
                {
                    value: this.docsGeneratorService.FormatAmountValue(
                        this.calculatePreTaxPriceLite(product.unitPrice, product.quantity, product.discount, product.discountType)
                    ), disabled: true
                },
                [Validators.compose([Validators.required, Validators.min(0), Validators.pattern(
                    "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"),]),]),
            //valueAddedTaxId: this.formBuilder.control({ value: product.valueAddedTaxId, disabled: true }, Validators.required),
            totalPrice: this.formBuilder.control(
                {
                    value: this.docsGeneratorService.FormatAmountValue(
                        this.calculateTTCAmountLite(product.unitPrice, product.quantity, product.discount, product.discountType,
                            product.valueAddedTaxId)
                    ), disabled: true
                },
                [Validators.compose([Validators.required, Validators.min(0), Validators.pattern(
                    "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"),]),]),
            originalQuantity: this.formBuilder.control(originalProduct.quantity),
            discount: this.formBuilder.control(this.docsGeneratorService.FormatAmountValue(product.discount ? product.discount : 0)),
            discountType: this.formBuilder.control(product.discountType ? product.discountType : "PERCENTAGE"),
            valueAddedTaxId: this.formBuilder.control({ value: product.valueAddedTaxId, disabled: true }, Validators.required),
            isService: this.formBuilder.control({ value: product.isService, disabled: true }, Validators.required),
            dsa: this.formBuilder.control({ value: product.dsa, disabled: true }),
            product: this.formBuilder.control(product ?? null),
        });
        prdct.controls.discount.setValidators(prdct.controls.discountType.value === 'PERCENTAGE' ?
            Validators.compose([Validators.min(0), Validators.pattern("^([0-9]{1,2}(.[0-9]{1,2}){0,1})$|^100.[0]{0,2}$"),]) :
            Validators.compose([Validators.min(0), Validators.pattern("^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$")]));
        if (prdct.controls.discountType.value === 'AMOUNT') {
            prdct.setValidators(this.docsGeneratorService.validateDiscountMaxValue);
        }
        return prdct;
    }

    newRebate(rebate: Rebate) {
        return this.formBuilder.group({
            name: this.formBuilder.control(rebate.name, Validators.required),
            valueAddedTaxId: this.formBuilder.control(rebate.valueAddedTaxId, Validators.required),
            amount: this.formBuilder.control(this.docsGeneratorService.FormatAmountValue(rebate.amount),
                Validators.compose([Validators.required, this.validatorsService.validateMinZero,
                Validators.pattern("^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"),
                ])),
            totalAmount: this.formBuilder.control({ value: this.docsGeneratorService.FormatAmountValue(rebate.totalAmount), disabled: true },
                Validators.compose([Validators.required, this.validatorsService.validateMinZero,
                Validators.pattern("^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$")])),
        })
    }


    /**
     * calculate TVA value
     * @param productFormArray productFormArray
     * @param controlIndex control controlIndex
     * @returns
     */
    calculateTVAValue(productFormArray, controlIndex) {
        return (
            this.calculateHTAmount(productFormArray, controlIndex) *
            (this.tvaList[productFormArray.controls[controlIndex].controls.valueAddedTaxId.value - 1]
                .value /
                100)
        );
    }

    /**
     * calculate TVA value
     * @param productFormArray productFormArray
     * @param controlIndex control controlIndex
     * @returns
     */
    calculateRebateTVAValue(rebatesFormArray, controlIndex) {
        return (
            this.docsGeneratorService.formatValueIntoNumber(
                rebatesFormArray.controls[controlIndex].controls.amount.value) *
            this.docsGeneratorService.formatValueIntoNumber(
                this.tvaList[rebatesFormArray.controls[controlIndex].controls.valueAddedTaxId.value - 1].value
            ) / 100
        );
    }

    calculateTVAValueLite(unitPrice, quantity, discount, discountType, valueAddedTaxId) {
        return (
            this.calculatePreTaxPriceLite(unitPrice, quantity, discount, discountType) *
            (this.tvaList[valueAddedTaxId - 1]
                .value /
                100)
        );
    }

    /**
     * calculate TTC Amount
     * @param productFormArray productFormArray
     * @param controlIndex control controlIndex
     * @returns
     */
    calculateTTCAmount(productFormArray, controlIndex) {
        return (
            this.formatValueIntoNumber(productFormArray.controls[controlIndex].controls.preTaxPrice.value) +
            this.calculateTVAValue(productFormArray, controlIndex)
        );
    }

    calculateTTCAmountLite(unitPrice, quantity, discount, discountType, valueAddedTaxId) {
        return (
            this.formatValueIntoNumber(this.calculatePreTaxPriceLite(unitPrice, quantity, discount, discountType)) +
            this.calculateTVAValueLite(unitPrice, quantity, discount, discountType, valueAddedTaxId,)
        );
    }


    /**
     * calculate HT Amount
     * @param productFormArray productFormArray
     * @param controlIndex control controlIndex
     * @returns
     */
    calculateHTAmount(productFormArray, controlIndex) {
        return this.calculatePreTaxPrice(productFormArray, controlIndex);
    }

    calculatePreTaxPriceLite(unitPrice, quantity, discount, discountType) {
        return (
            this.calculateProductPriceLite(unitPrice, quantity)
            - this.calculateDiscountLite(unitPrice, quantity, discount, discountType)
        );

    }

    calculateDiscount(productFormArray, controlIndex) {
        if (productFormArray.controls[controlIndex].controls.discountType.value === "PERCENTAGE") {
            return (this.calculateProductPriceLite(productFormArray.controls[controlIndex].controls.unitPrice.value,
                productFormArray.controls[controlIndex].controls.quantity.value) *
                (this.formatValueIntoNumber(productFormArray.controls[controlIndex].controls.discount.value) / 100))
        } else {
            return this.formatValueIntoNumber(productFormArray.controls[controlIndex].controls.discount.value);
        }
    }

    calculateDiscountLite(unitePrice, quantity, discount, discountType) {
        if (discountType === "PERCENTAGE") {
            return (this.calculateProductPriceLite(unitePrice, quantity) *
                (this.formatValueIntoNumber(discount) / 100))
        } else {
            return this.formatValueIntoNumber(discount);
        }
    }

    calculatePreTaxPrice(productFormArray, controlIndex) {
        return (
            this.formatValueIntoNumber(
                productFormArray.controls[controlIndex].controls.unitPrice.value
            ) *
            this.formatValueIntoNumber(
                productFormArray.controls[controlIndex].controls.quantity.value
            ) -
            this.formatValueIntoNumber(
                this.calculateDiscount(productFormArray, controlIndex)
            )
        );
    }

    /**
       * calculate product price
       * @param productFormArray productFormArray
       * @param controlIndex control controlIndex
       * @returns
       */
    calculateProductPrice(productFormArray, controlIndex) {
        return (
            this.formatValueIntoNumber(
                productFormArray.controls[controlIndex].controls.unitPrice.value
            ) *
            this.formatValueIntoNumber(
                productFormArray.controls[controlIndex].controls.quantity.value
            )
        );
    }

    calculateProductPriceLite(unitPrice, quantity) {
        return (
            this.formatValueIntoNumber(
                unitPrice
            ) *
            this.formatValueIntoNumber(
                quantity
            )
        );
    }

    /**
       * format value into number
       * @param value value to transform to number
       * @returns
       */
    formatValueIntoNumber(value) {
        const val = value ? value.toString().replaceAll(" ", "") : 0;
        return val && !isNaN(val) ? Number(val) : 0;
    }

    /**
 * calculate Total HT
 * @param formGroup form Group
 * @returns 
 */
    calculateTotalHTByDoc(formGroup, documentControlName) {
        let totalHT = 0;
        formGroup.controls[documentControlName].controls.forEach(deliveryNotecontrol => {
            deliveryNotecontrol.controls.productDTOS.controls.forEach(control => {
                totalHT = totalHT +
                    Number(this.formatValueIntoNumber(control.controls.preTaxPrice.value));
            });
        });

        if (formGroup.controls && formGroup.controls.rebateDTOS) {
            formGroup.controls.rebateDTOS.controls.forEach((control) => {
                totalHT = totalHT + this.formatValueIntoNumber(control.controls.amount.value);
            });
        }
        return this.docsGeneratorService.formatAmount(totalHT);
    }


    getDocFormControlName(docsType) {
        let controlName = '';
        switch (docsType) {
            case 'DELIVERY_NOTE':
                controlName = 'deliveryNotes';
                break;
            case 'INVOICE':
                controlName = 'invoices';
                break;
            case 'RECEIPT_VOUCHER':
                controlName = 'receiptVouchers';
                break;
            // case 'RETURN_PROVIDER':
            //     controlName = 'returnProviders';
            //     break;
            default:
                controlName = 'receiptVouchers';
                break;
        }
        return controlName;
    }


    getDocDtosFormControlName(docsType) {
        let controlName = '';
        switch (docsType) {
            case 'DELIVERY_NOTE':
                controlName = 'deliveryNotesDTOS';
                break;
            case 'INVOICE':
                controlName = 'invoicesDTOS';
                break;
            case 'RECEIPT_VOUCHER':
                controlName = 'receiptVouchersDTOS';
                break;
            default:
                controlName = 'receiptVouchersDTOS';
                break;
        }
        return controlName;
    }

    getDocumentIdName(docsType) {
        let controlName = '';
        switch (docsType) {
            case 'DELIVERY_NOTE':
                controlName = 'deliveryNoteId';
                break;
            case 'INVOICE':
                controlName = 'invoiceId';
                break;
            case 'RECEIPT_VOUCHER':
                controlName = 'receiptVoucherId';
                break;
            default:
                controlName = 'receiptVouchersId';
                break;
        }
        return controlName;
    }


    /**
 * calculate product price
 * @param productFormArray productFormArray
 * @param controlIndex control controlIndex
 * @returns
 */
    calculateDocsTotalHTDiscounted(docformGroup, documentControlName) {
        return (
            this.formatValueIntoNumber(
                this.calculateTotalHTByDoc(docformGroup, documentControlName)
            ) -
            this.formatValueIntoNumber(
                this.calculateTotalDiscountValue(docformGroup)
            )
        );
    }


    /**
     * calculate discount value
     * @param productFormArray productFormArray
     * @param controlIndex control controlIndex
     * @returns
     */
    calculateTotalDiscountValue(docformGroup) {
        if (docformGroup.controls.discountType.value === "PERCENTAGE") {
            return (
                Number(this.formatValueIntoNumber(docformGroup.controls.preTaxPrice.value)) *
                (Number(this.formatValueIntoNumber(
                    docformGroup.controls.discount.value
                )) /
                    100)
            );
        } else {
            return Number(this.formatValueIntoNumber(
                docformGroup.controls.discount.value
            ));
        }
    }


    calculateTotalRebates(docFormGroup) {
        let totalRebates = 0;
        docFormGroup.controls.rebateDTOS.controls.forEach(control => {
            totalRebates = totalRebates + this.formatValueIntoNumber(control.controls.totalAmount.value);
        })
        return totalRebates;
    }

    calculateRebateTotalAmount(rebatesFormArray, controlIndex) {
        return (this.docsGeneratorService.formatValueIntoNumber(
            rebatesFormArray.controls[controlIndex].controls.amount.value) *
            this.docsGeneratorService.formatValueIntoNumber(
                this.tvaList[rebatesFormArray.controls[controlIndex].controls.valueAddedTaxId.value - 1].value
            ) / 100)
            + this.docsGeneratorService.formatValueIntoNumber(
                rebatesFormArray.controls[controlIndex].controls.amount.value)
    }


}


