export const locale = {
    lang: 'fr',
    data: {
        titles:  {
            results: 'Résultats de l\'import',
            progress: 'Import en cours',

        },
        messages: {
        },
        buttons: {
        }
    },
};
