import { Injectable } from '@angular/core';
import { isNull } from 'lodash';
import { AbstractControl, UntypedFormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DocsGeneratorService } from './docs-generator.service';
import { Subject } from 'rxjs';

export function QuantityValidatorMax(maxValue: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const v: any = control.value;
    if (v && (Number(v.replaceAll(" ", "").
      replaceAll("\,", ".")) > maxValue)) {
      return { 'QuantityValidator': true, 'requiredValue': maxValue }
    }
    return null;
  }
}
@Injectable({
  providedIn: 'root'
})
export class ValidatorsService {
  maskNIFNIS = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,];
  maskarticleNum = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/,];
  maskNRC = [/\d/, /\d/, ' ', /[a-zA-Z]{1}/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '/', /\d/, /\d/];
  maskBN = [/[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/, /[a-zA-Z0-9]/,];
  maskCodeZip = [/\d/, /\d/, /\d/, /\d/, /\d/]
  maskSS = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  //internationalNumberValidator='^\+(?:[0-9] ?){6,14}[0-9]$';
  internationalNumberValidator = '^((00)|[+])[1-9][0-9]{8,14}$|^(0[1-9][0-9]{8,9})$';
  //internationalNumberValidator='^(\([0-9]{3}\)|[0-9]{3}-)[0-9]{3}-[0-9]{4}$';
  internationa;

  private checkValidationNotifier = new Subject<any>();
  checkValidationNotifier$ = this.checkValidationNotifier.asObservable();

  notifyCheckValidationChange(event) {
    this.checkValidationNotifier.next(event);
  }

  // tslint:disable-next-line: cognitive-complexity
  formatPhoneNumber(phoneNumber: string, phoneControl: any) {
    const input = document.getElementById('phoneNumberInput');
    // let posCaret = (input as HTMLInputElement).selectionStart;
    let posCaret = input ? (input as HTMLInputElement).selectionStart : 0;
    function setCaretPosition(ctrl, pos) {
      // Modern browsers
      if (ctrl.setSelectionRange) {
        ctrl.focus();
        ctrl.setSelectionRange(pos, pos);
        // IE8 and below
      } else if (ctrl.createTextRange) {
        const range = ctrl.createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
      }
    }

    if (phoneNumber && phoneNumber.split(' ').join('').length > 20) {
      phoneNumber = phoneNumber.substr(0, phoneNumber.length - 1);
      phoneControl.setValue(phoneNumber);
    }
    if (phoneNumber && !(/^[+]|[0-9]$/.test(phoneNumber.substr(phoneNumber.length - 1, 1)))) {
      phoneNumber = phoneNumber.substr(0, phoneNumber.length - 1);
      phoneControl.setValue(phoneNumber);
    }
    if (phoneNumber && phoneNumber.length >= 2) {
      phoneNumber = phoneNumber.replace(/ /g, '');
      let result;
      const deb = phoneNumber.substr(0, 2);
      let indicatif;
      let suite;
      if (deb === '00') {
        if (phoneNumber.length >= 3 && phoneNumber.length < 5) {
          phoneControl.setValue(phoneNumber.substr(0, 2) + ' ' +
            phoneNumber.substr(2, phoneNumber.length));
        } else {
          indicatif = Number(phoneNumber.charAt(5)) >= 5 ? phoneNumber.substr(0, 2) + ' ' +
            phoneNumber.substr(2, 3) + ' ' + phoneNumber.substr(5, 1) : phoneNumber.substr(0, 2) +
            ' ' + phoneNumber.substr(2, 3);
          suite = Number(phoneNumber.charAt(5)) >= 5 ? phoneNumber.substr(6, phoneNumber.length)
            : phoneNumber.substr(5, phoneNumber.length);
        }
      } else if (deb.substr(0, 1) === '0') {
        indicatif = Number(phoneNumber.charAt(1)) >= 5 ? phoneNumber.substr(0, 4)
          : phoneNumber.substr(0, 3);
        suite = Number(phoneNumber.charAt(1)) >= 5 ? phoneNumber.substr(4, phoneNumber.length)
          : phoneNumber.substr(3, phoneNumber.length);
      } else if (deb.substr(0, 1) === '+') {
        indicatif = Number(phoneNumber.charAt(4)) >= 5 ? phoneNumber.substr(0, 4)
          + ' ' + phoneNumber.substr(4, 1) : phoneNumber.substr(0, 4);
        suite = Number(phoneNumber.charAt(4)) >= 5 ? phoneNumber.substr(5, phoneNumber.length)
          : phoneNumber.substr(4, phoneNumber.length);
      } else {
        indicatif = '';
        suite = phoneNumber;
      }
      if (suite && suite.substr(2, 1) !== ' ') {
        let i = 0;
        result = suite.substr(i, 2);
        i += 2;
        while (i < suite.length) {
          result += ' ' + suite.substr(i, 2);
          i += 2;
          posCaret += 1;
        }
        // Set the cursor position of the "#test-input" element to the end when the page loads
        phoneControl.setValue((indicatif + ' ' + result).trim());
        if (input) {
          setCaretPosition(input, posCaret);
        }
        return (indicatif + ' ' + result).trim();
      }
    }
  }

  // tslint:disable-next-line: cognitive-complexity
  formatPhoneNumber2(phoneNumber: string) {
    if (phoneNumber && !(/^[+]|[0-9]$/.test(phoneNumber.substr(phoneNumber.length - 1, 1)))) {
      phoneNumber = phoneNumber.substr(0, phoneNumber.length - 1);
    }
    if (phoneNumber && phoneNumber.length >= 2) {
      phoneNumber = phoneNumber.replace(/ /g, '');
      let result;
      const deb = phoneNumber.substr(0, 2);
      let indicatif;
      let suite;
      if (deb === '00') {
        indicatif = Number(phoneNumber.charAt(5)) >= 5 ? phoneNumber.substr(0, 2) + ' ' +
          phoneNumber.substr(2, 3) + ' ' + phoneNumber.substr(5, 1) : phoneNumber.substr(0, 2) +
          ' ' + phoneNumber.substr(2, 3);
        suite = Number(phoneNumber.charAt(5)) >= 5 ? phoneNumber.substr(6, phoneNumber.length)
          : phoneNumber.substr(5, phoneNumber.length);

      } else if (deb.substr(0, 1) === '0') {
        indicatif = Number(phoneNumber.charAt(1)) >= 5 ? phoneNumber.substr(0, 4)
          : phoneNumber.substr(0, 3);
        suite = Number(phoneNumber.charAt(1)) >= 5 ? phoneNumber.substr(4, phoneNumber.length)
          : phoneNumber.substr(3, phoneNumber.length);
      } else if (deb.substr(0, 1) === '+') {
        indicatif = Number(phoneNumber.charAt(4)) >= 5 ? phoneNumber.substr(0, 4)
          + ' ' + phoneNumber.substr(4, 1) : phoneNumber.substr(0, 4);
        suite = Number(phoneNumber.charAt(4)) >= 5 ? phoneNumber.substr(5, phoneNumber.length)
          : phoneNumber.substr(4, phoneNumber.length);
      } else {
        indicatif = '';
        suite = phoneNumber;
      }
      if (suite && suite.substr(2, 1) !== ' ') {
        let i = 0;
        result = suite.substr(i, 2);
        i += 2;
        while (i < suite.length) {
          result += ' ' + suite.substr(i, 2);
          i += 2;
        }
        return indicatif + ' ' + result;
      }

    }
  }
  validatePhoneNumber(c: any) {
    if (c.value) {
      let matchescondition = false;
      // tslint:disable-next-line: deprecation
      if (!isNull(c.value)) {
        let valueControl: string = c.value;
        valueControl = valueControl.replace(/ /g, '');
        const matches = /^((00)|[+])213([2-4][0-9]{7}|[5-7][0-9]{8})$|^(0([2-4][0-9]{7})|())$|^(0([5-7][0-9]{8})|())$/.test(valueControl);
        // tslint:disable-next-line: no-redundant-boolean
        matchescondition = matches ? true : false;
      }
      return matchescondition ? null : {
        validePhoneNumber: {
          valid: false
        }
      };
    }
  }
  validateMobilePhoneNumber(c: any) {
    if (c.value) {
      let matchescondition = false;
      // tslint:disable-next-line: deprecation
      if (!isNull(c.value)) {
        let valueControl: string = c.value;
        valueControl = valueControl.replace(/ /g, '');
        const matches = /^((00)|[+])213([5-7][0-9]{8})$|^(0([5-7][0-9]{8})|())$/.test(valueControl);
        // tslint:disable-next-line: no-redundant-boolean
        matchescondition = matches ? true : false;
      }
      return matchescondition ? null : {
        validePhoneNumber: {
          valid: false
        }
      };
    }
  }
  validateFixPhoneNumber(c: any) {
    if (c.value) {
      let matchescondition = false;
      // tslint:disable-next-line: deprecation
      if (!isNull(c.value)) {
        let valueControl: string = c.value;
        valueControl = valueControl.replace(/ /g, '');
        const matches = /^((00)|[+])213([2-4][0-9]{7})$|^(0([2-4][0-9]{7})|())$/.test(valueControl);
        // tslint:disable-next-line: no-redundant-boolean
        matchescondition = matches ? true : false;
      }
      return matchescondition ? null : {
        validePhoneNumber: {
          valid: false
        }
      };
    }
  }
  validateAge(c: any) {
    if ((!c.value) || (c.value > 16 && c.value < 75)) {
      return null;
    } else {
      return {
        validateAge: {
          valid: false
        }
      };
    }
  }
  validateMinZero(c: any) {
    const value = c.value ? Number(c.value?.replaceAll(" ", "").replaceAll("\,", ".")) : null;
    if (value && value > 0) {
      return null;
    } else {
      return {
        validateMinZero: {
          valid: false
        }
      };
    }
  }
  validateHourNumberByDay(c: UntypedFormControl) {
    if ((!c.value) || (c.value > 0 && c.value <= 12)) {
      return null;
    } else {
      return {
        validateHourNumberByDay: {
          valid: false
        }
      };
    }
  }
  // tslint:disable-next-line: cognitive-complexity
  mask(): any {
    return {
      mask: (value) => {
        value = value.replace(/\s/g, '');
        if (value.length <= 2) {
          if (value.substr(0, 1) === '+') {
            return ['+', /\d/];
          } else {
            return ['0', /\d/];
          }
        } else if (value.length === 3) {
          if (value.substr(0, 2) === '00') {
            return ['0', '0', ' ', /[1-9]/];
          } else { return false; }
        } else if (value.length === 4) {
          if (value.substr(0, 2) === '00') {
            return ['0', '0', ' ', /[1-9]/, /\d/];
          } else if (value.substr(0, 2) === '02' || value.substr(0, 2) === '03' || value.substr(0, 2) === '04') {
            return ['0', /[2-4]/, /\d/, ' ', /\d/];
          } else { return false; }
        } else if (value.length === 5) {
          if (value.substr(0, 2) === '00') {
            return ['0', '0', ' ', /[1-9]/, /\d/, /\d/];
          } else if (value.substr(0, 1) === '0' && value.substr(1, 1) !== '2' && value.substr(1, 1) !== '3'
            && value.substr(1, 1) !== '4') {
            return ['0', /[1-9]/, /[1-9]/, /\d/, ' ', /\d/];
          } else if (value.substr(0, 1) === '+') {
            return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/];
          } else if (value.substr(0, 2) === '02' || value.substr(0, 2) === '03' || value.substr(0, 2) === '04') {
            return ['0', /[2-4]/, /\d/, ' ', /\d/, /\d/];
          } else { return false; }
        } else if (value.length === 6) {
          if (value.substr(0, 2) === '00') {
            return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/];
          } else if (value.substr(0, 1) === '0' && value.substr(1, 1) !== '2' && value.substr(1, 1) !== '3'
            && value.substr(1, 1) !== '4') {
            return ['0', /[1-9]/, /[1-9]/, /\d/, ' ', /\d/, /\d/];
          } else if (value.substr(0, 1) === '+') {
            if (value.substr(4, 1) === '2' || value.substr(4, 1) === '3' || value.substr(4, 1) === '4') {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/];
            } else {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/];
            }
          } else if (value.substr(0, 2) === '02' || value.substr(0, 2) === '03' || value.substr(0, 2) === '04') {
            return ['0', /[2-4]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
          } else { return false; }
        } else if (value.length === 7) {
          if (value.substr(0, 2) === '00') {
            if (value.substr(5, 1) === '2' || value.substr(5, 1) === '3' || value.substr(5, 1) === '4') {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/];
            } else {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/];
            }
          } else if (value.substr(0, 1) === '0' && value.substr(1, 1) !== '2' && value.substr(1, 1) !== '3'
            && value.substr(1, 1) !== '4') {
            return ['0', /[1-9]/, /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
          } else if (value.substr(0, 1) === '+') {
            if (value.substr(4, 1) === '2' || value.substr(4, 1) === '3' || value.substr(4, 1) === '4') {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/];
            } else {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/];
            }
          } else if (value.substr(0, 2) === '02' || value.substr(0, 2) === '03' || value.substr(0, 2) === '04') {
            return ['0', /[2-4]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else { return false; }
        } else if (value.length === 8) {
          if (value.substr(0, 2) === '00') {
            if (value.substr(5, 1) === '2' || value.substr(5, 1) === '3' || value.substr(5, 1) === '4') {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/];
            } else {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/];
            }
          } else if (value.substr(0, 1) === '0' && value.substr(1, 1) !== '2' && value.substr(1, 1) !== '3'
            && value.substr(1, 1) !== '4') {
            return ['0', /[1-9]/, /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else if (value.substr(0, 1) === '+') {
            if (value.substr(4, 1) === '2' || value.substr(4, 1) === '3' || value.substr(4, 1) === '4') {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/];
            } else {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/];
            }
          } else if (value.substr(0, 2) === '02' || value.substr(0, 2) === '03' || value.substr(0, 2) === '04') {
            return ['0', /[2-4]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
          } else { return false; }
        } else if (value.length === 9) {
          if (value.substr(0, 2) === '00') {
            if (value.substr(5, 1) === '2' || value.substr(5, 1) === '3' || value.substr(5, 1) === '4') {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/];
            } else {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/];
            }
          } else if (value.substr(0, 1) === '0' && value.substr(1, 1) !== '2' && value.substr(1, 1) !== '3'
            && value.substr(1, 1) !== '4') {
            return ['0', /[1-9]/, /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
          } else if (value.substr(0, 1) === '+') {
            if (value.substr(4, 1) === '2' || value.substr(4, 1) === '3' || value.substr(4, 1) === '4') {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
            } else {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            }
          } else if (value.substr(0, 2) === '02' || value.substr(0, 2) === '03' || value.substr(0, 2) === '04') {
            return ['0', /[2-4]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else { return false; }
        } else if (value.length === 10) {
          if (value.substr(0, 2) === '00') {
            if (value.substr(5, 1) === '2' || value.substr(5, 1) === '3' || value.substr(5, 1) === '4') {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
            } else {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            }
          } else if (value.substr(0, 1) === '0' && value.substr(1, 1) !== '2' && value.substr(1, 1) !== '3'
            && value.substr(1, 1) !== '4') {
            return ['0', /[1-9]/, /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else if (value.substr(0, 1) === '+') {
            if (value.substr(4, 1) === '2' || value.substr(4, 1) === '3' || value.substr(4, 1) === '4') {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            } else {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
            }
          } else if (value.substr(0, 2) === '02' || value.substr(0, 2) === '03' || value.substr(0, 2) === '04') {
            return ['0', /[2-4]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else { return false; }
        } else if (value.length === 11) {
          if (value.substr(0, 2) === '00') {
            if (value.substr(5, 1) === '2' || value.substr(5, 1) === '3' || value.substr(5, 1) === '4') {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            } else {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
            }
          } else if (value.substr(0, 1) === '0' && value.substr(1, 1) !== '2' && value.substr(1, 1) !== '3'
            && value.substr(1, 1) !== '4') {
            return ['0', /[1-9]/, /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else if (value.substr(0, 1) === '+') {
            if (value.substr(4, 1) === '2' || value.substr(4, 1) === '3' || value.substr(4, 1) === '4') {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
            } else {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            }
          } else if (value.substr(0, 2) === '02' || value.substr(0, 2) === '03' || value.substr(0, 2) === '04') {
            return ['0', /[2-4]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else { return false; }
        } else if (value.length === 12) {
          if (value.substr(0, 2) === '00') {
            if (value.substr(5, 1) === '2' || value.substr(5, 1) === '3' || value.substr(5, 1) === '4') {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
            } else {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            }
          } else if (value.substr(0, 1) === '0' && value.substr(1, 1) !== '2' && value.substr(1, 1) !== '3'
            && value.substr(1, 1) !== '4') {
            return ['0', /[1-9]/, /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else if (value.substr(0, 1) === '+') {
            if (value.substr(4, 1) === '2' || value.substr(4, 1) === '3' || value.substr(4, 1) === '4') {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            } else {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
            }
          } else if (value.substr(0, 2) === '02' || value.substr(0, 2) === '03' || value.substr(0, 2) === '04') {
            return ['0', /[2-4]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else { return false; }
        } else if (value.length === 13) {
          if (value.substr(0, 2) === '00') {
            if (value.substr(5, 1) === '2' || value.substr(5, 1) === '3' || value.substr(5, 1) === '4') {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            } else {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/];
            }
          } else if (value.substr(0, 1) === '0' && value.substr(1, 1) !== '2' && value.substr(1, 1) !== '3'
            && value.substr(1, 1) !== '4') {
            return ['0', /[1-9]/, /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else if (value.substr(0, 1) === '+') {
            if (value.substr(4, 1) === '2' || value.substr(4, 1) === '3' || value.substr(4, 1) === '4') {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            } else {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            }
          } else if (value.substr(0, 2) === '02' || value.substr(0, 2) === '03' || value.substr(0, 2) === '04') {
            return ['0', /[2-4]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else { return false; }
        } else if (value.length >= 14) {
          if (value.substr(0, 2) === '00') {
            if (value.substr(5, 1) === '2' || value.substr(5, 1) === '3' || value.substr(5, 1) === '4') {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            } else {
              return ['0', '0', ' ', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            }
          } else if (value.substr(0, 1) === '0' && value.substr(1, 1) !== '2' && value.substr(1, 1) !== '3' && value.substr(1, 1) !== '4') {
            return ['0', /[1-9]/, /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else if (value.substr(0, 1) === '+') {
            if (value.substr(4, 1) === '2' || value.substr(4, 1) === '3' || value.substr(4, 1) === '4') {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            } else {
              return ['+', /[1-9]/, /\d/, /\d/, ' ', /[1-9]/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
            }
          } else if (value.substr(0, 2) === '02' || value.substr(0, 2) === '03' || value.substr(0, 2) === '04') {
            return ['0', /[2-4]/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
          } else { return false; }
        } else { return false; }
      }, guide: false, keepCharPositions: true
    };
  }
  maskPoduct(): any {
    return {
      mask: (value) => {
        return [/[0-9]/];
      }
    }
  }

}


