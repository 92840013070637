import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImportPopupComponent } from './import-popup.component';
import { MaterialModule } from 'app/shared/material/material.module';
import { FuseSidebarModule } from '@fuse/components';
import { PopupSirhModule } from '../popUp/popup-sirh.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
    FuseSidebarModule,
    PopupSirhModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    TextMaskModule,
    FormsModule,
    
  ],
  declarations: [ImportPopupComponent],
  exports: [ImportPopupComponent]
})
export class ImportPopupModule { }
