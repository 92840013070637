import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CountingSheetPrintComponent } from './counting-sheet-print.component';
import { MaterialModule } from 'app/shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    TranslateModule,
  ],
  declarations: [CountingSheetPrintComponent],

  exports: [CountingSheetPrintComponent],
})
export class CountingSheetPrintModule { }
