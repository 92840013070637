import { MatBadge } from "@angular/material/badge";

export const locale = {
  lang: "fr",
  data: {
    FOLDER_NAV: {
      CONTEXT: "Gestion Dossier",
      DASHBOARD: {
        TITLE: "Tableau de bord",
        BADGE: "",
      },
      PROFILE: {
        TITLE: "Profil utilisateur",
        BADGE: "",
      },
      PRODUCT: {
        TITLE: "Produits / Services",
        BADGE: "",
      },
      STOCK: {
        TITLE: "Stock",
        BADGE: "",
      },

      FOLDER: {
        TITLE: "Dossier courant",
        BADGE: "",
      },
      CLIENTS: {
        TITLE: "Clients",
        BADGE: "",
      },
      SALES: {
        TITLE: "Ventes",
        BADGE: "",
      },
      PRICE_LIST: {
        TITLE: "Grille des tarifs",
        BADGE: "",
      },
      ENTRY_VOUCHERS: {
        TITLE: "Bons d'entrées",
        BADGE: "",
      },
      ALL_CLIENTS: {
        TITLE: "Clients",
        BADGE: "",
      },
      CLIENT_FAMILY: {
        TITLE: "Familles clients",
        BADGE: "",
      },
      QUOTE: {
        TITLE: "Devis",
        BADGE: "",
      },

      BILLS: {
        TITLE: "Factures",
        BADGE: "",
      },
      PRODUCT_FAMILY: {
        TITLE: "Familles produits",
        BADGE: "",
      },
      ASSETS: {
        TITLE: 'Avoirs',
        BADGE: ''
      },
      DELIVERY_NOTE: {
        TITLE: "Bons de livraison",
        BADGE: "",
      },
      RETURN_VOUCHER: {
        TITLE: "Retours",
      },
      RECEIPT_VOUCHER: {
        TITLE: "Bons de réception",
      },
      PURCHASES: {
        TITLE: "Achats",
        BADGE: "",

      },
      PROVIDERS: {
        TITLE: "Fournisseurs",
        BADGE: "",

      },
      PROVIDER_INVOICES: {
        TITLE: "Factures fournisseurs",
        BADGE: "",
      },
      CASHING: {
        TITLE: 'Encaissements',
        BADGE: ''
      },
      TREASURERSHIP: {
        TITLE: 'Trésorerie',
        BADGE: ''
      },
      BDC_PROVIDERS: {
        TITLE: 'BDC Fournisseurs',
        BADGE: ''
      },
      RETURN_PROVIDER: {
        TITLE: 'Retours fournisseurs',
        BADGE: ''
      },
      DISBURSMENTS: {
        TITLE: 'Décaissements',
        BADGE: ''
      },
      DEPOSITS: {
        TITLE: 'Dépôts',
        BADGE: ''
      },
      EXIT_VOUCHERS: {
        TITLE: "Bons de Sorties",
        BADGE: ""
      },
      TRANSFER_VOUCHERS: {
        TITLE: "Bons de Transferts",
        BADGE: ""
      }
      ,
      FUNDINGBOX: {
        TITLE: 'Caisses',
        BADGE: ''
      },
      INVENTORY: {
        TITLE: 'Inventaires',
        BADGE: ''
      },
      STATISTICS: {
        TITLE: "Statistiques",
        BADGE: "",
      },
      PRODUCTION: {
        TITLE: "Production",
        BADGE: "",
      },
      PROCESS: {
        TITLE: "Processus",
        BADGE: "",
      },
      EXECUTION: {
        TITLE: "Execution",
        BADGE: "",
      },
      SAV: {
        TITLE: "SAV",
        BADGE: "",
      },
      OPERATIONS: {
        TITLE: "Opérations",
        BADGE: "",
      },
      REPARATION_ORDER: {
        TITLE: "Ordres de réparation",
        BADGE: "",
      },
      SAV_QUATE: {
        TITLE: "Devis SAV",
        BADGE: "",
      },
      SAV_INTERVENTION: {
        TITLE: "intervention SAV",
        BADGE: "",
      },
      SAV_INVOICE: {
        TITLE: "Facture SAV",
        BADGE: "",
      },
      TECHNICIAN: {
        TITLE: "  Techniciens",
        BADGE: "",
      },
      VEHICL: {
        TITLE: "Véhicules",
        BADGE: "",
      },
      INTERVENTION: {
        TITLE: "Intervention",
        BADGE: "",
      },
      ORDER_VOUCHERS: {
        TITLE: "Bons de commande",
        BADGE: "",
      },
    },
    ADMIN_NAV: {
      CONTEXT: "Administration",
      ACCOUNT: {
        TITLE: "Compte client courant",
        BADGE: "",
      },
      CLIENT_ADMINS: {
        TITLE: 'Administrateurs',
        BADGE: '',
      },
      ACCOUNT_CLIENTS: {
        TITLE: 'Utilisateurs',
        BADGE: '',
      },
      TRACKS: {
        TITLE: 'Historique',
        BADGE: '',
      },
    },
    errors: {
      noBarcode: "Le produits sélectionné n'a pas de code barres. Veuillez d'abord le renseigner au niveau du produit.",
      createFolder: "Veuillez d'abord créer un dossier",
      standardErrorMessage403:
        "Vous n'êtes pas autorisé à accéder à ce module, veuillez contactez votre administrateur",
      standardErrorMessagePrivileges403:
        "Vous n'êtes pas autorisé à accéder à ce module, veuillez contactez votre admin",
      standardErrorMessage400: "Bad request: standard message",
      standardErrorMessage404:
        "Une erreur de connexion au serveur s'est produite, veuillez réessayer plus tard",
      standardErrorMessage500:
        "Une erreur de connexion au serveur s'est produite, veuillez réessayer plus tard",
      standardErrorMessage504: " Veuillez réessayer",
      accountnameexists: "Nom du compte client existant",
      accountAlreadyActivated: "Activation du compte échouée",
      phoneexists: "Numéro de téléphone existant",
      emailexists: "Adresse e-mail existante",
      errorParams: "Adresse e-mail et/ou mot de passe incorrect",
      infoText: 'Veuillez renseigner : ',
      commercialRegisterNumber: 'Registre de commerce, ',
      taxationArticle: 'Article d\'imposition, ',
      bankAccountNumber: 'RIB, ',
      nif: 'NIF, ',
      nis: 'NIS, ',
      infoFolderText: ' dans dossier avant la création',
      infoClientText: ' dans client avant la création',
      startNumber: 'Vous avez déjà créé les documents suivants, vous ne pouvez pas changer le numéro de départ : \n',
      E001: "Durée non valide",
      E002: "Quota d'administrateurs du compte client atteint",
      E003: "Durée ou abonnement manquants",
      E004: "Quota d'utilisateurs du compte client atteint",
      E005: "Module dupliqué",
      E006: "Une invitation a été déjà envoyée",
      E007: "Module requis",
      E008: "L'utilisateur est déjà membre",
      E009: "Utilisateur BACK OFFICE",
      E010: "Mot de passe incorrect",
      E011: "Nom de l'abonnement existant",
      E012: "Abonnement utilisé par un compte client",
      E013: "Abonnement utilisé par un compte client actif",
      E014: "Recaptcha non valide",
      E015: "Le nombre des dossier du compte client est supérieur à celui de l'abonnement",
      E016: "Le nombre d'employés du compte client est supérieur à celui de l'abonnement",
      E017: "Le stckage du compte client est supérieur à celui de l'abonnement",
      E018: "Abonnement inexistant ou inactif",
      E019: "Abonnement obligatoire",
      E020: "Unité de stockage obligatoire",
      E021: "Le compte doit être nouveau ou désactivé",
      E022: "Aucun utilisateur n'est lié à cette clé d'activation",
      E023: "Aucun utilisateur inactif est associé à cet employé",
      E025: "Nom de domaine non valide",
      E026: "Merci de renseigner une adresse e-mail existante",
      E100: "Commune non valide",
      E101: "Activité non valide",
      E102: "Wilaya non valide",
      E103: "Nom existant",
      E104: "Activité requise",
      E173: "Champs obligatoires",
      E361: "Champs obligatoires",
      E999:
        "Pour votre sécurité, votre compte est temporairement bloqué pour une durée de 5 minutes, " +
        "car il y a eu trop de tentatives d'authentification en échec",
      E603: 'Code Barre produit déja existant',
      E600: 'Produit existant',
      E604: "Prénom requis",
      E605: "Nom requis",
      E606: "Raison social requise",
      E607: "Forme juridique requise",
      E608: "Nom et prénom existants",
      E609: "Raison social existante",
      E610: "Code existant",
      E611: "Type du fichier non pris en charge",
      E612: "Taille de l'image  maximale dépassée",
      E616: "Nom tarif existant",
      E615: "Famille client dispose déja d'un tarif ",
      E622: "Echec de la suppression, le client a des documents de vente",
      E627: 'Nom de bon de livraison existant',
      E628: 'Nom de facture existant',
      E629: 'Produit utilisé dans grille de tarifs',
      E614: 'Cette famille contient des clients, vous ne pouvez pas la supprimer',
      E619: 'La remise ne doit pas dépasser le total',
      E626: 'les informations fiscales sont obligatoires',
      E631: "Champs  obligatoires manquants",
      E638: "Cette famille contient des produits. Veuillez supprimer préalablement les produits avant de recommencer.",
      E636: 'La facture est liée à un avoir',
      E637: 'Le nom de famille produit existe déja',
      E601: "Qte Max doit être supérieure à Qte Min",
      E613: "Une famille client porte déjà ce nom",
      E617: "La date échéance doit être supérieure à la date du document",
      E618: "Le type de la remise est obligatoire",
      E620: "Le produit doit avoir un nom",
      E621: "Quantité invalide",
      E623: "Le produit apparait dans un document",
      E624: "Statut Devis invalide",
      E625: "Statut BL invalide",
      E630: "Le client est inactif",
      E632: "Le code fournisseur existe déjà",
      E633: "Nom du Retour invalide",
      E634: "Le BL est lié a un Retour",
      E635: "Nom de l'Avoir invalide",
      E639: "Le nom du fournisseur existe déjà",
      E640: "L'un des produits retirés apparait dans un Retour associé",
      E641: "L'un des produits retirés apparait dans un Avoir associé",
      E642: "Un ou plusieurs champs requis ne sont pas renseignés",
      E643: "Nom du Bon de réception invalide",
      E644: "Le fournisseur a déjà des documents",
      E645: "Nom de la Facture fournisseur invalide",
      E647: 'Vous ne pouvez pas valider ce document, Les quantités de certains produits sont à 0',
      E648: 'Au moins une facture est liée à un encaissement brouillon',
      E664: 'Le document est déja associé a un décaissement',
      E663: 'Le tag est associé a un document',
      E674: "Vous avez déjà un dépôt principal.",
      E651: 'Au moins une facture fournisseur est liée à un décaissement prévu',
      E676: 'ID dépot invalide',
      E682: 'Vous ne pouvez pas modifier l\'emplacement par defaut',
      E690: 'Bon de transfert lié a ce dépot',
      E691: 'Inventaire est lié a ce dépot',
      E692: 'Ce dépot contient des produits',
      E695: 'Impossible de supprimer un emplacment contenant des produits',
      E696: 'Vous ne pouvez pas suppimer l\'emplacement par defaut',
      E665: 'Le nom du produit existe déja',
      E701: 'Le bon de réception est déjà relié a un retour fournisseur',
      E717: 'La caisse contient des enc/dec',
      E707: 'Caisse obligatoire',
      E716: 'Collisage invalide',
      E715: 'Bon de retour invalide',
      E713: 'Bon de transfert invalide',
      E712: 'Fournisseur invalide',
      E112: 'Quota des dossiers du compte client atteint',
      E721: 'Pas plus d\'un seul colisage favouris',
      E751: 'Ordre de réparation, liée à une ou plusieurs interventions',
      E752: 'Ordre de réparation, liée à un ou plusieurs devis',
      E113: 'Compte Client désactivé',
      E735: 'Le document contient des numéros de série doublants',
      E753: 'Le véhicule est hors service',
      E667: 'La référance de l\'inventaire existe déja',
      E754: 'Véhicule est attaché à l\'intervention ',
      E737: 'Le numéro d\'immatriculation existe déjà !',
      E100_004: 'Bons d\'entrées ',
      E100_005: 'Bons de sorties ',
      E100_006: 'Bons de transferts ',
      E100_007: 'Bons de livraison ',
      E100_008: 'Devis ',
      E100_009: 'Avoir ',
      E100_010: 'Factures ',
      E100_011: 'Bons de réception ',
      E100_012: 'Bons de retours ',
      E100_013: 'Factures fournisseurs ',
      E100_014: 'BDC Fournisseur ',
      E100_015: 'Retours Fournisseurs ',
      E731: 'Adresse mail invalide',
      E732: 'Le client est associé à des exécutions nouvelles / en cours',
      E733: 'Le devis est associé a des exécutions nouvelles / en cours',
      E736: 'Le bon contient des produits doublant. Veuillez fusionner les quantités ou mettre des emplacements différents.',
      E741: 'Un utilisateur avec cet email existe déjà',
      E756: 'Un utilisateur avec ce numéro de téléphone existe déjà',
      E755: 'Téchnicien inactif',
      E744: 'Le BL est utilisé dans le SAV',
      E759: 'Format invalide!. Chaque ligne doit avoir 2 elements séparés par un \';\'',
      E758: 'Le fichier contient un ou plusieurs codes barres erronées',
      E734: 'Des numéros de série existent déja.',
      E702: 'Sélectionner au moins un produit',
      E760: 'Cette panne ne peut pas être supprimé, vous pouvez la désactiver',
      E761: 'Quota maximum atteint',
    },
    popup: {
      newVersion:
        "Une nouvelle version de l'application front est disponible, cliquez ci-dessous pour actualiser.",
      refresh: "Actualiser",
      autoPage: "La page va être automatiquement actualisée dans",
      secondes: "secondes.",
    },
    noFolder: {
      oops: "Bienvenue sur FAST",
      create1:
        "Veuillez créer un dossier pour commencer votre expérience FAST,",
      create2: "Merci.",
      add: "Créer un dossier",
      logout: "Se déconnecter",
    },
    noAccess: "Vous n'êtes pas autorisé à effectuer cette action",
    expiredAccount:
      "Votre abonnement a expiré, veuillez contacter votre administrateur",
    expiredAbonnement: "Abonnement expiré",
  },
};
