import { NotifiersService } from './notifiers.service';
import { Subject } from 'rxjs';
import { StorageUtils } from './../shared/storage-utils';
import { environment } from './../../environments/environment.prod';
import { ComponentRef, Injectable } from '@angular/core';
import * as Stomp from 'stompjs';
import { Client } from '@stomp/stompjs';

import * as SockJS from 'sockjs-client';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ImportPopupComponent } from 'app/shared/components/popUps/import-popup/import-popup.component';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  /**
 * @webSocketEndPoint EndPoint 
 * @topic topic to subscribe
 * @stompClient client socket
 */
  webSocketEndPoint: string = environment.ENDPOINTS.WS;
  topic = environment.ENDPOINTS.WS_TOPIC;
  stompClient: Client;
  /**
   * @reconnecting 
   * @maxReconnectAttempts
   * @reconnectAttempts
   * @reconnectTimeout
   */
  private reconnecting = false;
  private maxReconnectAttempts = 10;
  private reconnectAttempts = 0;
  private reconnectTimeout = 5000;
  private isConnected = false;
  /**
   * 
   * @param notifierService 
   * @param authService
   */
  private finishedImport = new Subject<any>();
  finishedImport$ = this.finishedImport.asObservable();
  count: number;
  timeToWait: number;
  tentation: number;
  connexionTimeOut: any;
  waitingTimeOut: any;
  trayingToConnect = false;
  popUpTable: any[] = [];
  x = '20px';
  y = 20;
  resultSocket: any
  socketData: any
  private socketSubject: Subject<any> = new Subject();

  constructor(private notifierService: NotifiersService,
    private overlay: Overlay,
    private positionBuilder: OverlayPositionBuilder,
    private authService: AuthService,
  ) { }
  // connect() {
  //   this.trayingToConnect = true;
  //   const authToken = StorageUtils.getAuthToken();
  //   const headers: any = {};
  //   headers['Authorization'] = `Bearer ${authToken}`;
  //   const socket = new SockJS(this.webSocketEndPoint + `?access_token=` + authToken);
  //   this.stompClient = Stomp.over(socket);
  //   this.stompClient.connect(headers, () => {
  //     this.trayingToConnect = false;
  //     this.isConnected = true;
  //     this.count = 0;
  //     this.timeToWait = 10000;
  //     this.tentation = 1;
  //     let topic = this.topic;
  //     let receivedRows = [];
  //     this.stompClient.subscribe(topic, (object) => {

  //       const wsObject = JSON.parse(object.body);
  //       this.showCostomPopUp(wsObject,"progress")

  //       // tslint:disable-next-line: no-small-switch
  //       if (wsObject.type === 'IMPORT_PRODUCT') {
  //         this.notifierService.completeImportProducts(wsObject);
  //       } else if (wsObject.type === 'IMPORT_client') {    

  //         this.notifierService.completeImportClients(wsObject);
  //       }
  //     }, headers, (error) => {
  //       setTimeout(() => {
  //         this.tryConnect();
  //       }, 2000);

  //     });
  //   }, this.errorCallback);

  // }

  /**
  * connect handle the connection and onClose of the WS
  */
  connect() {
    console.log('Initialize web socket ...');
    const socket = new SockJS(this.webSocketEndPoint);
    this.stompClient = new Client();
    this.stompClient.webSocketFactory = () => socket;
    this.stompClient.onConnect = (frame) => {
      console.log('### Web socket Connected ###');
      this.isConnected = true;
      this.reconnectAttempts = 0; // Réinitialiser le compteur en cas de connexion réussie
      this.reconnecting = false;
      this.reconnectTimeout = 5000;
      this.stompClient.subscribe(this.topic, (message) => {
        const wsObject = JSON.parse(message.body);

        switch (wsObject.type) {
          case 'IMPORT_PRODUCT':
            this.notifierService.completeImportProducts(wsObject);
            break;
          case 'IMPORT_CLIENT_ITEM':
            this.notifierService.updatePopTableSizes(wsObject);
            break;
          case 'IMPORT_client':
            this.notifierService.updatePopTableSizes(wsObject);
            break;
          case 'IMPORT_PRODUCT_ITEM':
            this.notifierService.completeImportProducts(wsObject);
            break;

          default:
            break;
        }
      });
    };
    //  display debug messages in the console and PING PONG messages
    this.stompClient.debug = (str) => {
      console.log(str);
    };
    this.stompClient.onWebSocketClose = () => {
      this.notifierService.websocketDeconnectedStatus(true);
      this.isConnected = false;
      console.log('### Oops ! WebSocket connection closed. ###');
      this.reconnecting = false;
      this.reconnect();
    };

    this.stompClient.activate();
  }
  /**
   * reconnect handle the reconnection of the WS
   */

  private reconnect() {
    if (!this.reconnecting && this.reconnectAttempts < this.maxReconnectAttempts) {
      this.reconnecting = true;
      console.log(`### Reconnecting in ${this.formatDuree(this.reconnectTimeout)}...###`);

      setTimeout(() => {
        this.authService.getCSRFToken().subscribe(); // get new CSRF token
        this.reconnectAttempts++;
        console.log('### Reconnect attempt number ' + this.reconnectAttempts + ' ###');
        if(navigator.onLine){
          this.connect();
        }
        
      }, this.reconnectTimeout);
    } else if (this.reconnectTimeout <= 2360000) {
      console.log('### Max reconnect attempts reached. increase waiting time by two and restart reconnect attempts ###');
      this.reconnectAttempts = 0;
      this.maxReconnectAttempts--;
      this.reconnectTimeout = this.reconnectTimeout * 2;
      this.reconnect();
    } else {
      console.log('Max reconnect attempts reached.  Giving up.');

    }
  }
  formatDuree(ms) {
    let secondes = Math.floor(ms / 1000);
    let minutes = Math.floor(secondes / 60);
    let heures = Math.floor(minutes / 60);
    const jours = Math.floor(heures / 24);

    if (jours > 0) {
      heures %= 24;
      minutes %= 60;
      secondes %= 60;
      return `${jours} jour(s), ${heures} heure(s), ${minutes} minute(s), ${secondes} seconde(s)`;
    } else if (heures > 0) {
      minutes %= 60;
      secondes %= 60;
      return `${heures} heure(s), ${minutes} minute(s), ${secondes} seconde(s)`;
    } else if (minutes > 0) {
      secondes %= 60;
      return `${minutes} minute(s), ${secondes} seconde(s)`;
    } else {
      return `${secondes} seconde(s)`;
    }
  }
  // errorCallback = () => {
  //   this.stompClient.disconnect();
  //   setTimeout(() => {
  //     this.tryConnect();
  //   }, 2000);
  // }

  tryConnect() {
    if (this.trayingToConnect) {
      this.tentation++;
    }
    if (this.tentation % 3 === 0) {
      this.timeToWait = this.timeToWait * 2;
    }
    this.waitingTimeOut = setTimeout(() => {
      this.connect();
    }, this.timeToWait);
  }

  // disconnect() {
  //   if (this.stompClient && this.stompClient.connected) {
  //     this.stompClient.disconnect();
  //   }
  // }
  getConnectionStatus() {
    return this.isConnected;
  }


  showCostomPopUp(res: any, type?: string) {
    if (this.popUpTable.length > 0 && this.popUpTable.length < 1) {
      this.popUpTable.forEach(popup => {
        this.updateOverlayPosition(popup.overlayRef, popup.componentRef, type, '+');
      });
    }
    if (this.popUpTable.length > 0) {
      this.popUpTable.forEach(popup => {
        popup.overlayRef.detach();
        popup.componentRef.destroy();
      });
      this.popUpTable = [];
    }

    const overlayRef = this.overlay.create({
      positionStrategy: this.overlay.position().global().right(this.x).bottom(`${this.y}px`),
      hasBackdrop: false,

    });
    const componentPortal = new ComponentPortal(ImportPopupComponent);
    const componentRef = overlayRef.attach(componentPortal);

    componentRef.instance.instanceRef = componentRef;
    res.row = res.row ?? 0;
    res.proportion = (res.row * 100) / res.total;
    componentRef.instance.data = res;
    if (type === 'progress') {
      componentRef.instance.progressStatus = true;
      componentRef.instance.infoImport = false;
    } else if (type === 'info') {
      componentRef.instance.progressStatus = false;
      componentRef.instance.infoImport = true;



    } else {
      componentRef.instance.progressStatus = false;
      componentRef.instance.infoImport = false;

    }
    const pupUp = {
      overlayRef,
      componentRef,
      type
    };
    this.popUpTable.push(pupUp);
  }
  updateOverlayPosition(overlayRef: OverlayRef, componentRef?: any, type?: string, operation?: string, height?: number) {
    const index = this.popUpTable.findIndex(popup => popup.componentRef === componentRef);

    if (overlayRef && componentRef) {
      const position: any = overlayRef;
      const bottomPosition: number = parseInt(position._positionStrategy._bottomOffset.replace('px', ''), 10);
      switch (type) {
        case 'progress':
          if (height === undefined) {
            height = 65;
          }
          const positionStrategy = this.positionBuilder.global().right(this.x).bottom(`${this.getNewBottonPosition(bottomPosition, operation, height)}px`);
          overlayRef.updatePositionStrategy(positionStrategy);
          this.popUpTable[index].overlayRef = overlayRef;
          break;
        case 'info':
          if (height === undefined) {
            height = 105;
          }
          const positionStrategy1 = this.positionBuilder.global().right(this.x).bottom(`${this.getNewBottonPosition(bottomPosition, operation, height)}px`);
          overlayRef.updatePositionStrategy(positionStrategy1);
          this.popUpTable[index].overlayRef = overlayRef;
          break;
        default:
      }
    }
  }
  getNewBottonPosition(bottomPosition, operation, set): number {
    if (operation === '+') {
      return bottomPosition + set;
    } else {
      return bottomPosition - set;
    }
  }

}
