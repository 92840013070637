import { OnInit, Component } from '@angular/core';

@Component({
    selector: 'app-sirh-loader',
    templateUrl: './sirh-loader.component.html',
    styleUrls: ['./sirh-loader.component.scss']
})
export class SIRHLoaderComponent implements OnInit {


    
    ngOnInit() {

    }

}