import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DataStorageService } from './data.service';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class VersionCheckService {

    versionUrl = environment.ENDPOINTS.VERSION_URL;

    constructor(private httpClient: HttpClient, private cacheStorageService: DataStorageService) { }

    checkVersion() {
        // timestamp these requests to invalidate caches
        const req =this.versionUrl + '?t=' + new Date().getTime();
        // return this.httpClient.get(this.versionUrl + '?t=' + new Date().getTime());
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem(req)).pipe(
              catchError(() => this.httpClient.get<any>(req, { observe: 'response' }))
            );
          }
          return this.httpClient.get<any>(req, { observe: 'response' }).pipe(
            switchMap(response => {
              this.cacheStorageService.setItem(req, response);
              return of(response);
            })
          );
    }
}