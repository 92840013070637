import { environment } from 'environments/environment';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Credentials } from '../shared/models/credentials.model';
import { HttpClient } from '@angular/common/http';
import { StorageUtils } from '../shared/storage-utils';
import { Password } from '../shared/models/password.model';
import { DataStorageService } from './data.service';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authenticated = false;
    authUrl = environment.ENDPOINTS.PUBLIC_ROUTE;
    constructor(private http: HttpClient, private cacheStorageService: DataStorageService) { }

    /**
     * get login url by platform
     */
    getAuthUrl(): Observable<any>{
        const req = this.authUrl;
        // return this.http.get<any>(req);
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem(req)).pipe(
              catchError(() => this.http.get<any>(req))
            );
          }
          return this.http.get<any>(req).pipe(
            switchMap(response => {
              this.cacheStorageService.setItem(req, response);
              return of(response);
            })
          );
    }
    /**
     * call to get csrf token
     */
    getCSRFToken(): Observable<any> {
        // return this.http.get<any>(environment.ENDPOINTS.CSRF_URL, { observe: 'response' });
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem(environment.ENDPOINTS.CSRF_URL)).pipe(
              catchError(() => this.http.get<any>(environment.ENDPOINTS.CSRF_URL, { observe: 'response' }))
            );
          }
          return this.http.get<any>(environment.ENDPOINTS.CSRF_URL, { observe: 'response' }).pipe(
            switchMap(response => {
              this.cacheStorageService.setItem(environment.ENDPOINTS.CSRF_URL, response);
              return of(response);
            })
          );
    }

    login(credentials: Credentials, resp: any): Observable<any> {
        return this.http.post(environment.ENDPOINTS.LOGIN_URL + `?response=${resp}`, credentials);
    }

    /**
     * check authentification for a user
     */
    checkAuthentication(): Observable<any> {
        // return this.http.get<any>(environment.ENDPOINTS.ACCOUNT_URL);
        if (!navigator.onLine) {
            return from(this.cacheStorageService.getItem(environment.ENDPOINTS.ACCOUNT_URL)).pipe(
                catchError(() =>this.http.get<any>(environment.ENDPOINTS.ACCOUNT_URL))
            );
        }
        return this.http.get<any>(environment.ENDPOINTS.ACCOUNT_URL).pipe(
            switchMap(response => {
                this.cacheStorageService.setItem(environment.ENDPOINTS.ACCOUNT_URL, response);
                return of(response);
            })
        );
    }

    /**
     * check if authenticated
     */
    isAuthenticated(): boolean {
        return this.authenticated;
    }
    /**
     * set if is authenticated
     * @param auth;
     */
    // tslint:disable-next-line:typedef
    setAuthenticated(auth: boolean) {
        this.authenticated = auth;
    }

    /**
     * disconnect the user
     */
    logout(): Observable<any> {
        return this.http.post(environment.ENDPOINTS.LOGOUT_URL, '');
    }
    /**
     * check if the token is expired
     */
    isTokenExpired(): boolean {
        const token = StorageUtils.getAuthToken();
        if (token) {
            let decoded = null;
            try {
                decoded = jwt_decode(token);
            } catch (error) {
                return true;
            }
            if (decoded) {
                const dateExp = new Date(0);
                dateExp.setUTCSeconds(decoded.exp);

                // return date;
                if (!(dateExp.valueOf() > new Date().valueOf())) {
                    // token expired, returns true
                    return true;
                } else {
                    // token not expired yet , returns false
                    return false;
                }
            }
        } else {
            // no token so returns true
            return true;
        }
    }

    /**
     * edit password
     * @param password Password Account Model
     */
    editPassword(password: Password): Observable<any> {
        const req = environment.ENDPOINTS.CHANGE_PASSWORD_URL;
        return this.http.post(req, password);
    }

}
function jwt_decode(token: any) {
    // TODO
    return 0;
}

