<router-outlet *ngIf="!login"></router-outlet>
<ng-http-loader *ngIf="!login" [entryComponent]="SIRHLoaderComponent" [filteredMethods]="['gEt']" opacity=".9" [minDuration]="500"
    [filteredUrlPatterns]="['logout']"></ng-http-loader>

<ng-template *ngIf="!login" #reloadPopup>
    <div fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap="10px">
        <div>
            {{'popup.newVersion' | translate}}
        </div>
        <button class="sirh-btn-primary" mat-raised-button matDialogClose color="primary" (click)="reload()">
            {{'popup.refresh'| translate}}

        </button>
        <div>
            {{'popup.autoPage' | translate}} {{secondsBeforeReload}} {{'popup.secondes'| translate}}
        </div>
    </div>

</ng-template>
<!--if the user is not connected then show auth app-->
<iframe *ngIf="login" name="iframe" id="iframe" title="iframe" [src]="url" width="100%" style="border: unset;">
</iframe>   

