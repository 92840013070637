import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { StorageUtils } from 'app/shared/storage-utils';
import { Router } from '@angular/router';

@Component({
    selector: 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    classes = 'nav-item';
    folderId: number;
    @Input()
    item: FuseNavigationItem;

    // Private
    private _unsubscribeAll: Subject<any>;
    route: string;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private router: Router
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    getFolderId(): number {
        return StorageUtils.getSelectedFolderId();
    }

    getAccountId(): number {
        return StorageUtils.getSelectedAccountId();
    }

    navigateTo(item: FuseNavigationItem, event?, righClick?) {
        const id = StorageUtils.getContext() === 'admin' ? this.getAccountId() : this.getFolderId();
        const currentItem = localStorage.getItem('selectedItem');
        if ((currentItem && currentItem === item.id) && (!righClick)) {
            // this.router.navigateByUrl('', { skipLocationChange: true }).then(() => {
            if (item.parentRoute === '/folder' && item.isParentRoute) {
                this.router.navigate(['folder', this.getFolderId(), 'view']);
            } else if (item.parentRoute !== '/folder' && item.isParentRoute) {
                this.router.navigate(['/' + item.parentRoute, this.getAccountId(), item.url]);
            } else {
                this.router.navigate(['/' + item.parentRoute + '/' + id + '/' + item.url]);
            }
            // });
        } else {
            let id = StorageUtils.getContext() === 'admin' ? this.getAccountId() : this.getFolderId();
            id = id === null ? 0 : id;
            let route = [];
            if (item.parentRoute === '/folder') {
                route = item.isParentRoute ? [item.url, id, 'view']
                    : item.parentRoute ? ['/' + item.parentRoute, id, item.url] : [item.url];
            } else {
                route = item.parentRoute ? ['/' + item.parentRoute, id, item.url] : [item.url];
            }

            this.route = '/' + item.parentRoute + '/' + id + '/' + item.url;
            if ((event.ctrlKey || righClick) && !item.isParentRoute) {
                window.open('/' + item.parentRoute + '/' + id + '/' + item.url);
            } else if ((event.ctrlKey || righClick) && item.isParentRoute) {
                window.open(item.url + '/' + id + '/view');
            } else {
                localStorage.setItem('selectedItem', item.id);
                this.router.navigate(route);
            }
        }
    }

    activeLink(itemUrl, parentItem) {
        const activeRoute = this.router.url;
        const items = activeRoute.split('/');
        if ((parentItem && items.find(item => item === 'view') && items.length <= 5) || (!parentItem && items.find(item => itemUrl === item))) {
            return { 'active': true, 'accent2': true }

        } else {
            return {}
        }
    }

    rightClick(item: FuseNavigationItem, event?) {
        event.stopPropagation();
        event.preventDefault();
        this.navigateTo(item, event, true);
    }
}
