<div class="loader-container" fxLayout="column" fxLayoutAlign="center center">
    <div class="spinner" aria-hidden="true"></div>
    <!-- <div class="wrapper">
        <div class="square1 commun-style">R</div>
        <div class="square2 commun-style">H</div>
        <div class="square3 commun-style">P</div>
        <div class="square4 commun-style">A</div>
        <div class="square5 commun-style">R</div>
        <div class="square6 commun-style">T</div>
        <div class="square7 commun-style">N</div>
        <div class="square8 commun-style">E</div>
        <div class="square9 commun-style">R</div>
    </div> -->
</div>