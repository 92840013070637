import { Component, Input, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { ExportService } from 'app/services/export.service';
import { NotifiersService } from 'app/services/notifiers.service';
import { StorageUtils } from 'app/shared/storage-utils';
import { locale as francais } from "../import-popup/i18n/fr";
import { locale as arabe } from "../import-popup/i18n/dz";
import saveAs from 'file-saver';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
@Component({
  selector: 'app-import-popup',
  templateUrl: './import-popup.component.html',
  styleUrls: ['./import-popup.component.scss'],
  animations: fuseAnimations
})
export class ImportPopupComponent implements OnInit {

  @Input() instanceRef: any;
  @Input() data: any;
  @Input() progressStatus: boolean;
  @Input() infoImport: boolean;
  @Input() borderColor: String;
  isCLosed:boolean = false
  constructor(
    private notificationService: NotifiersService,
    private exportService:ExportService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    ) { 
      this.fuseTranslationLoaderService.loadTranslations(francais, arabe);
     }

  ngOnInit() {
    
    
  }
  /**
 * close the pop up
 * destroy the component instance
 * remove the component instance from the popUpTable at the button-bar component using observable
 */
  close() {
    this.instanceRef.destroy();
    if(this.progressStatus){
      this.isCLosed = true;
    }
   
    // this.notificationService.removeDestroyedCostemPopupFunc(this.instanceRef);  
  }
  showDetails() {
    if(this.data.type=="IMPORT_client" || this.data.type=="IMPORT_CLIENT_ITEM"){
    
        this.exportService.downloadClientsRapport(StorageUtils.getSelectedFolderId(), this.data.clientImportId).subscribe(res => {
          const typeDoc = res.headers.get('content-type');
          const filName = StorageUtils.getSelectedFolder().name + '-Clients';
          if (typeDoc.includes(this.exportService.TYPE_FORMAT)) {
            const blobXLS = new Blob([res.body], { type: this.exportService.EXCEL_TYPE });
            saveAs.saveAs(blobXLS, filName + this.exportService.EXCEL_EXTENSION);
          }
    
        })
      
    }
    else{
      this.exportService.downloadImportProductsRapport(StorageUtils.getSelectedFolderId(), this.data.productImportId).subscribe(res => {
        const typeDoc = res.headers.get('content-type');
        const filName = StorageUtils.getSelectedFolder().name+'-Produits';
        if (typeDoc.includes(this.exportService.TYPE_FORMAT)) {
            const blobXLS = new Blob([res.body], { type: this.exportService.EXCEL_TYPE });
            saveAs.saveAs(blobXLS, filName + this.exportService.EXCEL_EXTENSION);
        }

    })
    }
  }
}
