<ng-container *ngIf="!item.hidden">

    <!-- item.url -->
    <a class="nav-link" [ngClass]="activeLink(item.url, item.isParentRoute)" [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && !item.function && !item.isParentRoute"
        [routerLinkActive]="['active', 'accent']" (contextmenu)="rightClick(item,$event);"
        (click)="navigateTo(item,$event);" [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
        [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>
    <a class="nav-link" [ngClass]="activeLink(item.url, item.isParentRoute)" [ngClass]="item.classes"
        *ngIf="item.isParentRoute" [routerLinkActive]="['active', 'accent']" (contextmenu)="rightClick(item,$event);"
        (click)="navigateTo(item,$event)" [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
        [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && !item.function"
        [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.function -->
    <span class="nav-link" [ngClass]="item.classes" *ngIf="!item.url && item.function" (click)="item.function()">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </span>

    <!-- item.url && item.function -->
    <a class="nav-link" [ngClass]="activeLink(item.url, item.isParentRoute)" [ngClass]="item.classes"
        *ngIf="item.url && !item.externalUrl && item.function" (click)="item.function()"
        [routerLink]="item.parentRoute ? [item.parentRoute, folderId,item.url]: [item.url]"
        [routerLinkActive]="['active', 'accent']" [routerLinkActiveOptions]="{exact: item.exactMatch || false}"
        [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>

    <!-- item.externalUrl && item.function -->
    <a class="nav-link" [ngClass]="item.classes" *ngIf="item.url && item.externalUrl && item.function"
        (click)="item.function()" [href]="item.url" [target]="item.openInNewTab ? '_blank' : '_self'">
        <ng-container *ngTemplateOutlet="itemContent"></ng-container>
    </a>


    <!--url with svg icons-->
    <ng-template #itemContent>
        <mat-icon class="nav-link-icon"
            [ngStyle]="{'background-color': item.color, 'color' : (item.isParentRoute || item.id === 'dashboard' ) ? 'white' : '#a7b1c2'}"
            *ngIf="item.icon && !item.svgIcon">
            {{item.icon}}</mat-icon>
        <mat-icon class="nav-link-icon" [ngStyle]="{'background-color': item.color}" *ngIf="item.icon && item.svgIcon"
            svgIcon="{{item.icon}}"></mat-icon>
        <span class="nav-link-title" [translate]="item.translate">{{(item.translate | translate) || item.title}}</span>
        <span class="nav-link-badge" *ngIf="item.badge" [translate]="item.badge.translate"
            [ngStyle]="{'background-color': item.badge.bg,'color': item.badge.fg}">
            {{(item.badge.translate | translate) || item.badge.title}}
        </span>
    </ng-template>

</ng-container>