<div *ngIf="infoImport" class="pop-container" [ngStyle]="{'height':'100px'}"
    [@animate]="{ value: '*', params: { delay: '300ms', x: '100%' } }">
    <div class="btn-action-container" [ngClass]="borderColor">
        <div class="progress-title">{{'titles.results' | translate}}</div>
        <!-- <button (click)="close()" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
            <mat-icon>close</mat-icon>
        </button> -->
        <mat-icon (click)="close()" class="cloes-btn">close</mat-icon>
    </div>
    <div class="main-container">
        <div class="content">
            <div class="title">
                <div> Réussis:</div>
            </div>
            <div class="description">
                <p> {{data.written ?? '/'}} </p>
            </div>
        </div>
        <div class="content">
            <div class="title">
                <div> Echoués : </div>
            </div>
            <div class="description">
                <p> {{data.skipped ?? '/'}} </p>
            </div>
        </div>
        <div class="detail-link">
            <a href="" (click)="$event.preventDefault();showDetails();" target="_blank"> Détails </a>
        </div>
    </div>
</div>
<div *ngIf="progressStatus && !isCLosed" class="pop-container" [ngStyle]="{'height':'60px'}">
    <div class="btn-action-container2" [ngClass]="borderColor">
        <div class="progress-title">{{'titles.progress' | translate}}</div>
        <!-- <button (click)="close()" mat-icon-button aria-label="Example icon button with a vertical three dot icon">
            <mat-icon>close</mat-icon>
        </button> -->
        <mat-icon (click)="close()" class="cloes-btn">close</mat-icon>
    </div>
    <div class="progress-event">
        <mat-progress-bar mode="determinate" [value]="data.proportion"></mat-progress-bar>
    </div>
    <div class="value">{{( data.row < 0 ? 0 : data.row ) + '/' + data.total }}</div>
    </div>