import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrintContingSheetPopupComponent } from './print-conting-sheet-popup.component';
import { MaterialModule } from 'app/shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from 'app/shared/shared.module';
import { NgxPrintModule } from 'ngx-print';
import { CountingSheetPrintModule } from '../../counting-sheet-print/counting-sheet-print.module';

@NgModule({
  declarations: [PrintContingSheetPopupComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    NgxDatatableModule,
    FuseSharedModule,
    MaterialModule,
    TranslateModule,
    SharedModule,
    NgxPrintModule,
    CountingSheetPrintModule,
    
  ],
  exports: [PrintContingSheetPopupComponent]

})
export class PrintContingSheetPopupModule { }
 