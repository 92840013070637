/* eslint-disable no-useless-escape */
/* eslint-disable no-var */
import { Injectable } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { collisageTypesList } from "app/main/pages/folder/products/add-product/collisage/collisage.component";
import { isNull } from "lodash";
import { ValidatorsService } from "./validators.service";
import { DataStorageService } from "./data.service";
import { StorageUtils } from "app/shared/storage-utils";
@Injectable({
  providedIn: "root",
})
export class DocsGeneratorService {
  constructor(
    private translateService: TranslateService,
    private formBuilder: FormBuilder,
    private validatorsService: ValidatorsService,
    private cacheStorageService: DataStorageService
  ) { }
  formatAmount(value) {
    let val = value ? value.toString().replaceAll(" ", "") : 0;
    val = Number(val.toString().replaceAll(",", ".")) * 100;
    val = (Math.round(Number(Number.parseFloat(val).toPrecision(15))) / 100)
      .toFixed(2)
      .toString();
    return this.FixScientificNotation(val).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      " "
    );
  }
  validateAmount(c: FormControl) {
    // let val = c.value ? c.value.toString().replaceAll(" ", "") : 0;
    // val = Number(val.toString().replaceAll("\,", ".")) * 100;
    // val = (Math.round(Number(Number.parseFloat(val).toPrecision(15))) / 100).toFixed(2).toString();
    // //this.FixScientificNotation(val).replace(/^-?\d{1,3}(,\d{3})*(\.\d{1,2})?$/g, " ")
    // return val;
    if (c.value) {
      let value = +(c.value + "");
      if (!isNull(c.value)) {
        const valueControl: string = c.value;
        value = +(valueControl + "").replace(",", ".");
      }
      return !isNaN(+value)
        ? null
        : {
          valideNumber: {
            valid: false,
          },
        };
    }
  }

  formatDiscount(value, discountType) {
    let val = value ? value.toString().replaceAll(" ", "") : 0;
    val = Number(val.toString().replaceAll(",", ".")) * 100;
    val = (Math.round(Number(Number.parseFloat(val).toPrecision(15))) / 100)
      .toFixed(2)
      .toString();
    if (discountType === "PERCENTAGE" && Number(val) % 1 === 0) {
      val = Number(val).toFixed(0).toString();
    }
    return this.FixScientificNotation(val).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      " "
    );
  }

  formatAmountPce(value) {
    let val = value ? value.toString().replaceAll(" ", "") : 0;
    val = Number(val).toString();
    return this.FixScientificNotation(val).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      " "
    );
  }
  isDiscountTypeAmountForSpecificControl(controlIndex, productFormArray) {
    if (
      productFormArray &&
      productFormArray.controls[controlIndex] &&
      productFormArray.controls[controlIndex].controls.discountType.value &&
      productFormArray.controls[controlIndex].controls.discountType.value ===
      "AMOUNT"
    ) {
      return true;
    } else {
      return false;
    }
  }

  formatDocumentNumber(value) {
    //let val = Number(value).toString()
    return this.FixScientificNotation(value).replace(/\d\d?\/\d{3}/gm, " ");
  }
  getSelectedUnit(controlIndex, productFormArray, unitsList) {
    // if we have a selected unit && the unit selected has exist in units list
    if (
      productFormArray &&
      productFormArray.controls[controlIndex] &&
      productFormArray.controls[controlIndex].controls.measureUnitId.value &&
      unitsList &&
      unitsList.find(
        (unit) =>
          unit.id ===
          productFormArray.controls[controlIndex].controls.measureUnitId.value
      )
    ) {
      return unitsList.find(
        (unit) =>
          unit.id ===
          productFormArray.controls[controlIndex].controls.measureUnitId.value
      ).name;
    } else {
      return null;
    }
  }
  calculateTTCAmount(productFormArray, controlIndex, tvaList) {
    return (
      this.formatValueIntoNumber(
        productFormArray.controls[controlIndex].controls.preTaxPrice.value
      ) + this.calculateTVAValue(productFormArray, controlIndex, tvaList)
    );
  }
  calculateHTAmount(productFormArray, controlIndex) {
    return (
      this.calculateProductPrice(productFormArray, controlIndex) -
      this.calculateDiscountValue(productFormArray, controlIndex)
    );
  }
  calculateProductPrice(productFormArray, controlIndex) {
    return (
      this.formatValueIntoNumber(
        productFormArray.controls[controlIndex].controls.unitPrice.value
      ) *
      this.formatValueIntoNumber(
        productFormArray.controls[controlIndex].controls.quantity.value
      )
    );
  }
  calculateDiscountValue(productFormArray, controlIndex) {
    if (
      productFormArray.controls[controlIndex].controls.discountType.value ===
      "PERCENTAGE"
    ) {
      return (
        this.calculateProductPrice(productFormArray, controlIndex) *
        (this.formatValueIntoNumber(
          productFormArray.controls[controlIndex].controls.discount.value
        ) /
          100)
      );
    } else {
      return this.formatValueIntoNumber(
        productFormArray.controls[controlIndex].controls.discount.value
      );
    }
  }
  formatValueIntoNumber(value) {
    const val = value ? value.toString().replaceAll(" ", "") : 0;
    return val && !isNaN(val) ? Number(val) : 0;
  }
  calculateTVAValue(productFormArray, controlIndex, tvaList) {
    const data = productFormArray.controls[controlIndex].controls.valueAddedTaxId
      .value
      ? productFormArray.controls[controlIndex].controls.valueAddedTaxId.value
      : 3;
    return (
      this.calculateHTAmount(productFormArray, controlIndex) *
      (tvaList[data - 1]?.value / 100)
    );
  }
  formatQuantities(value, UNIT_TYPE?) {
    let val = value ? value.toString().replaceAll(" ", "") : 0;
    val = Number(val.toString().replaceAll(",", "."));
    if (UNIT_TYPE) {
      val = Math.round(Number(val));
      val = Number(val).toString();
      return this.FixScientificNotation(val).replace(
        /\B(?=(\d{3})+(?!\d))/g,
        " "
      );
    } else {
      if (Number(val) % 1 != 0) {
        val = (Math.round(Number(val) * 100) / 100).toFixed(2).toString();
        return this.FixScientificNotation(val).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          " "
        );
      } else {
        val = Number(val).toFixed(0).toString();
        return this.FixScientificNotation(val).replace(
          /\B(?=(\d{3})+(?!\d))/g,
          " "
        );
      }
    }
  }

  getFullAddress(folder, wilaya, commune, type?): string {
    let address = "";
    if (type === "company") {
      if (folder.street) {
        address = folder.street + ", ";
      }
      if (folder.commune) {
        address =
          address +
          (commune.find((c) => c.code === folder.commune)
            ? commune.find((c) => c.code === folder.commune).name
            : "") +
          ", ";
      }
      if (folder.state) {
        address =
          address +
          (wilaya.find((w) => w.code === folder.state)
            ? wilaya.find((w) => w.code === folder.state).name
            : "");
      }
    } else {
      const adressArgs = [
        folder.address,
        commune.find((c) => c.code === folder.communeId)?.name,
        wilaya.find((w) => w.code === folder.wilayaId)?.name,
      ];
      adressArgs.forEach((item) => {
        if (item) {
          if (address) {
            address = address + ", " + item;
          } else {
            address = item;
          }
        }
      });
    }
    return address;
  }
  /**
   * replace all non digit caracter
   * @param value value to format
   * @returns
   */
  revokeLettreInput(value) {
    return value.replaceAll(/(?![\. \,])[\D]/g, "");
  }

  formatNumber(value: number): any {
    const numStr = String(value).replace(/[^\d.-]/g, "");
    const num = parseFloat(numStr);

    if (isNaN(num)) {
      // If the input is not a valid number, return an empty string
      return "";
    }

    // Use the built-in currency pipe to format the number with a currency symbol and thousands separators
    //return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(num);
    return num;
  }
  /**
   * calculate Total HT
   * @param formGroup form Group
   * @returns
   */
  calculateTotalHT(formGroup) {
    let totalHT = 0;
    formGroup.controls.productDTOS.controls.forEach((control) => {
      // if (!control.controls.dsa.value) {
      totalHT =
        totalHT +
        Number(this.formatValueIntoNumber(control.controls.preTaxPrice.value));
      // }
    });
    return this.formatAmount(totalHT);
  }
  calculateTth(formGroup) {
    let totalHT = 0;
    formGroup.controls.receiptVouchersDTOS.controls.forEach((control) => {
      control.controls.productDTOS.controls.forEach((e) => {
        totalHT =
          totalHT +
          Number(this.formatValueIntoNumber(e.controls.preTaxPrice.value));
      });
    });
    return this.formatAmount(totalHT);
  }
  /**
   * calculate product price
   * @param productFormArray productFormArray
   * @param controlIndex control controlIndex
   * @returns
   */
  calculateTotalHTDiscounted(formGroup) {
    return (
      this.formatValueIntoNumber(this.calculateTotalHT(formGroup)) -
      this.formatValueIntoNumber(this.calculateTotalDiscountValue(formGroup))
    );
  }
  /**
   * calculate discount value
   * @param productFormArray productFormArray
   * @param controlIndex control controlIndex
   * @returns
   */
  calculateTotalDiscountValue(formGroup) {
    if (formGroup.controls.discountType.value === "PERCENTAGE") {
      return (
        Number(
          this.formatValueIntoNumber(formGroup.controls.preTaxPrice.value)
        ) *
        (Number(this.formatValueIntoNumber(formGroup.controls.discount.value)) /
          100)
      );
    } else {
      return Number(
        this.formatValueIntoNumber(formGroup.controls.discount.value)
      );
    }
  }
  /**
   * calculate product price with tarification
   * @param productFormArray productFormArray
   * @param controlIndex control controlIndex
   * @returns
   */
  calculateProductPriceTarification(product, tarif) {
    let price = 0;
    // if product selected exist in database thene if he has purchasePrice && weightedAveragePrice
    if (product.id && product) {
      switch (tarif && tarif.base) {
        case "WEIGHTED_AVERAGE_PRICE":
          if (
            product.weightedAveragePrice !== null &&
            product.weightedAveragePrice !== undefined
          ) {
            price =
              product.weightedAveragePrice +
              product.weightedAveragePrice * (tarif.rate / 100);
          } else {
            price = null;
          }
          break;
        case "PURCHASE_PRICE":
          if (
            product.purchasePrice !== null &&
            product.purchasePrice !== undefined
          ) {
            price =
              product.purchasePrice +
              product.purchasePrice * (tarif.rate / 100);
          } else {
            price = null;
          }
          break;
      }
      return price;
    } else {
      return null;
    }
  }
  getProductPrice(product, tarif) {
    if (tarif && tarif.rate !== null && tarif.rate !== undefined) {
      return this.calculateProductPriceTarification(product, tarif);
    } else if (tarif && tarif.amount) {
      return tarif.amount;
    } else if (tarif && tarif.label) {
      return tarif.label;
    } else if (tarif && tarif.htPrice) {
      return tarif.htPrice;
    } else return 0;
  }
  getClientFamilyPrices(
    productFormArray,
    controlIndex,
    products,
    clientFamilyPrices,
    quote
  ) {
    const productId =
      productFormArray.controls[controlIndex].controls.productId.value;
    const controlPrices = [...clientFamilyPrices];
    let productPreTaxPrice = null;
    if (productId) {
      productPreTaxPrice = products.find((product) => product.id === productId)
        ? products.find((product) => product.id === productId).preTaxPrice
        : null;
    }
    if (
      quote &&
      quote.productDTOS.length > 0 &&
      quote.productDTOS[controlIndex] &&
      quote.productDTOS[controlIndex].productId === productId
    ) {
      const productData = quote.productDTOS[controlIndex];
      const Oldprice = {
        name: this.translateService.instant("documents.inputs.oldPrice"),
        rate: null,
        price: productData.unitPrice,
        value: productData.unitPrice,
      };
      controlPrices.unshift(Oldprice);
    }
    if (productPreTaxPrice) {
      const price = {
        name: this.translateService.instant("documents.inputs.priceHT"),
        rate: null,
        price: productPreTaxPrice,
        value: productPreTaxPrice,
      };
      controlPrices.push(price);
      return controlPrices;
    } else {
      return controlPrices;
    }
  }
  // todo fix docModule in other docs
  newProduct(
    quote,
    docFormGroup,
    productFormArray,
    clientFamilyPrices,
    docModule?,

    clientDsa?
  ): FormGroup {
    if (!quote) {
      const itemId =
        docFormGroup.controls.clientId && docFormGroup.controls.clientId.value
          ? docFormGroup.controls.clientId.value
          : docFormGroup.controls.providerId &&
            docFormGroup.controls.providerId.value
            ? docFormGroup.controls.providerId.value
            : docFormGroup.controls.targetDepositId &&
              docFormGroup.controls.targetDepositId.value
              ? docFormGroup.controls.targetDepositId
              : docFormGroup.controls.emitterDepositId &&
                docFormGroup.controls.emitterDepositId.value
                ? docFormGroup.controls.emitterDepositId
                : null;
      return this.newInitialProduct(productFormArray, itemId, docModule, clientDsa);
    } else {
      return this.newSetedValuesProduct(
        quote,
        productFormArray,
        clientFamilyPrices,
        docModule,
        clientDsa
      );
    }
  }
  locationConditionallyRequiredValidator(formControl: AbstractControl) {
    if (!formControl.parent) {
      return null;
    }

    if (formControl.parent.get("locationInDepositId").value) {
      return Validators.required(formControl);
    }
    return null;
  }
  newInitialProduct(productFormArray, itemId, docModule?, clientDsa?): FormGroup {
    const product = this.formBuilder.group({
      productId: this.formBuilder.control(null, Validators.required),
      product: this.formBuilder.control(null),
      locationInDepositId: this.formBuilder.control(null),
      name: this.formBuilder.control(null, Validators.required),
      barCode: this.formBuilder.control(null),
      measureUnitId: this.formBuilder.control(1, Validators.required),
      unitPriceSelector: this.formBuilder.control(null),
      free: this.formBuilder.control({ value: false, disabled: true }),
      unitPrice: this.formBuilder.control(
        this.FormatAmountValue(0),
        Validators.compose(
          docModule && docModule !== "STOCK"
            ? [
              Validators.required,
              Validators.pattern(
                "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"
              ),
              this.validatorsService.validateMinZero,
            ]
            : null
        )
      ),
      quantity: this.formBuilder.control(
        this.FormatQuantity(0, 0, productFormArray),
        Validators.compose([
          Validators.required,
          Validators.pattern(
            "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"),
          this.validatorsService.validateMinZero,
        ])
      ),
      preTaxPrice: this.formBuilder.control(
        { value: this.FormatAmountValue(0), disabled: true },
        [
          Validators.compose([
            Validators.required,
            Validators.min(0),
            Validators.pattern(
              "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"
            ),
          ]),
        ]
      ),
      discount: this.formBuilder.control(this.formatDiscount(0, "PERCENTAGE"), [
        Validators.compose([
          Validators.min(0),
          Validators.pattern("^([0-9]{1,2}(.[0-9]{1,2}){0,1})$|^100.[0]{0,2}$"),
        ]),
      ]),
      discountType: this.formBuilder.control("PERCENTAGE"),
      valueAddedTaxId: this.formBuilder.control(3, Validators.required),
      priceBeforeDiscount: this.formBuilder.control({
        value: 0,
        disabled: true,
      }),
      totalPrice: this.formBuilder.control(
        { value: this.FormatAmountValue(0), disabled: true },
        [
          Validators.compose([
            Validators.required,
            Validators.min(0),
            Validators.pattern(
              "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"
            ),
          ]),
        ]
      ),
      packagingValue: this.formBuilder.control({ value: 0, disabled: true }),
      packagingTypeId: this.formBuilder.control({ value: 0, disabled: true }),
      packagingCalculatedValue: this.formBuilder.control({
        value: 1,
        disabled: true,
      }),
      isService: this.formBuilder.control(null),
      dsa: this.formBuilder.control({ value: false, disabled: true }),
    });
    if (!itemId) {
      // product.controls.productId.disable();
      product.controls.name.disable();
      product.controls.locationInDepositId.disable();
      product.controls.barCode.disable();
    }
    this.disableProductControls(product);
    return product;
  }
  disableProductControls(product) {
    product.controls.unitPrice.disable();
    product.controls.quantity.disable();
    product.controls.measureUnitId.disable();
    product.controls.discount.disable();
    product.controls.discountType.disable();
    product.controls.valueAddedTaxId.disable();
    //  product.controls.locationInDepositId.disable();
    return product;
  }
  newSetedValuesProduct(
    quoteProduct,
    productFormArray,
    clientFamilyPrices,
    docModule?,
    clientDsa?
  ): FormGroup {
    const productsLength = productFormArray.controls.length;
    const product = this.formBuilder.group({
      id: this.formBuilder.control(quoteProduct.id),
      productId: this.formBuilder.control(
        quoteProduct.productId ? quoteProduct.productId : null
      ),
      product: this.formBuilder.control(
        quoteProduct.product ? quoteProduct.product : null
      ),
      barCode: this.formBuilder.control(
        quoteProduct.barCode ? quoteProduct.barCode : null
      ),
      name: this.formBuilder.control(
        quoteProduct.name ? quoteProduct.name : null,
        Validators.required
      ),
      measureUnitId: this.formBuilder.control(
        quoteProduct.measureUnitId ? quoteProduct.measureUnitId : null,
        Validators.required
      ),
      isService: this.formBuilder.control(
        quoteProduct.isService ? quoteProduct.isService : false
      ),
      unitPriceSelector: this.formBuilder.control(
        clientFamilyPrices.length > 0 ? clientFamilyPrices[0] : null
      ),
      free: this.formBuilder.control({ value: quoteProduct.free, disabled: docModule !== 'VENTES' }),
      unitPrice: this.formBuilder.control(
        {
          value: quoteProduct.unitPrice
            ? this.FormatAmountValue(quoteProduct.unitPrice)
            : 0,
          disabled: quoteProduct.free
        },
        Validators.compose(
          docModule && docModule !== "STOCK"
            ? [
              Validators.required,
              Validators.pattern(
                "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"
              ),
              this.validatorsService.validateMinZero,
            ]
            : null
        )
      ),
      locationInDepositId: this.formBuilder.control(
        quoteProduct.locationInDepositId
          ? quoteProduct.locationInDepositId
          : null
      ),
      quantity: this.formBuilder.control(
        quoteProduct.quantity
          ? this.FormatQuantity(
            quoteProduct.quantity,
            productsLength - 1,
            productFormArray
          )
          : null,
        Validators.compose([
          Validators.required,
          Validators.pattern(
            "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"
          ),
          this.validatorsService.validateMinZero,
        ])
      ),
      preTaxPrice: this.formBuilder.control(
        {
          value: this.FormatAmountValue(
            quoteProduct.preTaxPriceAfterDiscount
              ? quoteProduct.preTaxPriceAfterDiscount
              : 0
          ),
          disabled: true,
        },
        [
          Validators.compose([
            Validators.required,
            Validators.min(0),
            Validators.pattern(
              "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"
            ),
          ]),
        ]
      ),
      discount: this.formBuilder.control(
        this.formatDiscount(
          quoteProduct.discount
            ? quoteProduct.discount
            : quoteProduct.warrenty
              ? 100
              : 0,
          quoteProduct.discountType
        )
      ),
      discountType: this.formBuilder.control(
        quoteProduct.discountType ? quoteProduct.discountType : "PERCENTAGE"
      ),
      valueAddedTaxId: this.formBuilder.control(
        quoteProduct.valueAddedTaxId ? quoteProduct.valueAddedTaxId : 3,
        Validators.required
      ),
      priceBeforeDiscount: this.formBuilder.control({
        value: this.FormatAmountValue(
          quoteProduct.preTaxPrice ? quoteProduct.preTaxPrice : 0
        ),
        disabled: true,
      }),
      totalPrice: this.formBuilder.control(
        {
          value: this.FormatAmountValue(
            quoteProduct.totalPrice ? quoteProduct.totalPrice : 0
          ),
          disabled: true,
        },
        [
          Validators.compose([
            Validators.required,
            Validators.min(0),
            Validators.pattern(
              "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"
            ),
          ]),
        ]
      ),
      packagingValue: this.formBuilder.control(
        quoteProduct.packagingValue ?? null
      ),
      packagingTypeId: this.formBuilder.control(
        quoteProduct.packagingTypeId ? quoteProduct.packagingTypeId : null
      ),
      packagingCalculatedValue: this.formBuilder.control(
        quoteProduct.packagingCalculatedValue
          ? quoteProduct.packagingCalculatedValue
          : null
      ),
      dsa: this.formBuilder.control(
        { value: quoteProduct.dsa ? quoteProduct.dsa : false, disabled: !StorageUtils.getSelectedFolder().dsa || clientDsa === false || docModule === 'ACHATS' }

      ),
    });
    if (quoteProduct.productId) {
      product.controls.measureUnitId.disable();
    }
    product.controls.discount.setValidators(
      product.controls.discountType.value === "PERCENTAGE"
        ? Validators.compose([
          Validators.min(0),
          Validators.pattern(
            "^([0-9]{1,2}(.[0-9]{1,2}){0,1})$|^100.[0]{0,2}$"
          ),
        ])
        : Validators.compose([
          Validators.min(0),
          Validators.pattern(
            "^([0-9]{1,3})( [0-9]{3})*(.[0-9]{1,2}){0,1}$|^([0-9]*)( ){0,1}(.[0-9]{1,2}){0,1}$"
          ),
        ])
    );
    if (product.controls.discountType.value === "AMOUNT") {
      product.setValidators(this.validateDiscountMaxValue);
    }
    return product;
  }
  FormatAmountValue(value) {
    const val = value ? value : 0;
    if (
      isNaN(Number(val.toString().replaceAll(" ", "").replaceAll(",", ".")))
    ) {
      return val;
    } else {
      return this.formatAmount(val);
    }
  }
  FormatQuantity(value, controlIndex, productFormArray) {
    let val = value ? value : 0;
    val = Number(val.toString().replaceAll(",", "."));
    if (isNaN(Number(val.toString().replaceAll(" ", "")))) {
      return val;
    } else {
      if (
        productFormArray.controls[controlIndex] &&
        productFormArray.controls[controlIndex].controls.measureUnitId.value ===
        4
      ) {
        return this.formatQuantities(val, true);
      } else {
        return this.formatQuantities(val, false);
      }
    }
  }
  FixScientificNotation(value) {
    if (!isNaN(Number(value))) {
      if (Math.abs(value) < 1.0) {
        var e = parseInt(value.toString().split("e-")[1]);
        if (e) {
          value *= Math.pow(10, e - 1);
          value = "0." + new Array(e).join("0") + value.toString().substring(2);
        }
      } else {
        var e = parseInt(value.toString().split("+")[1]);
        if (e > 20) {
          e -= 20;
          value /= Math.pow(10, e);
          value += new Array(e + 1).join("0");
        }
      }
    }
    return value;
  }
  public validateDiscountMaxValue(Productgroup: FormGroup) {
    const discountControl = Productgroup.controls["discount"];
    const priceBeforeDiscountControl = Productgroup.controls[
      "priceBeforeDiscount"
    ]
      ? Productgroup.controls["priceBeforeDiscount"]
      : Productgroup.controls["preTaxPrice"];
    const discountValue = discountControl.value
      ? discountControl.value.toString().replaceAll(" ", "") &&
        !isNaN(discountControl.value.toString().replaceAll(" ", ""))
        ? Number(discountControl.value.toString().replaceAll(" ", ""))
        : 0
      : 0;
    const priceBeforeDiscount = priceBeforeDiscountControl.value
      ? priceBeforeDiscountControl.value.toString().replaceAll(" ", "") &&
        !isNaN(priceBeforeDiscountControl.value.toString().replaceAll(" ", ""))
        ? Number(
          priceBeforeDiscountControl.value.toString().replaceAll(" ", "")
        )
        : 0
      : 0;
    if (priceBeforeDiscount < discountValue) {
      discountControl.setErrors({ discountMaxValueError: true });
    }
    return null;
  }

  formatQuantitiesWithHalfQuantities(value, UNIT_TYPE?) {
    let val = value ? value.toString().replaceAll(" ", "") : 0;
    val = Number(val.toString().replaceAll(",", "."));

    if (UNIT_TYPE) {
      val = Number(val).toFixed(2);
      val = Number(val).toString();
      return this.FixScientificNotation(val).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } else {
      if (Number(val) % 1 != 0) {
        return val.toFixed(2).toString();
      } else {
        val = Number(val).toFixed(0).toString();
        return this.FixScientificNotation(val).replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      }
    }
  }
}
