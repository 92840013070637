import { Injectable } from '@angular/core';
import { openDB, IDBPDatabase } from 'idb';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataStorageService {

  private dbPromise: Promise<IDBPDatabase>;

  constructor() {
    this.dbPromise = openDB('my-app', 3, {
      upgrade(db) {
        db.createObjectStore('facture');
        db.createObjectStore('data');
        db.createObjectStore('product');
        db.createObjectStore('clients');
        db.createObjectStore('productFamily');
        db.createObjectStore('clientsFamily');
        db.createObjectStore('tarif');
        db.createObjectStore('devis');
        db.createObjectStore('purchase-orders');
        db.createObjectStore('bl');
        db.createObjectStore('cachings');
        db.createObjectStore('disbursements');
        db.createObjectStore('cash-desks');
        db.createObjectStore('providers');
        db.createObjectStore('productClientFamiliesPriseList');
        db.createObjectStore('uploadedDataInfos');

        db.createObjectStore('clientsFamilyAdded');
        db.createObjectStore('clientsAdded');
        db.createObjectStore('productFamilyAdded');
        db.createObjectStore('productAdded');
        db.createObjectStore('tarifAdded');

        db.createObjectStore('devisAdded');
        db.createObjectStore('purchaseOrdersAdded');
        db.createObjectStore('blAdded');
        db.createObjectStore('factureAdded');
        db.createObjectStore('cashingsAdded');
        db.createObjectStore('disbursementsAdded');


        db.createObjectStore('localStorageShared');

      }
    });
  }

  async setItem(key: any, value: any, storeName?, fromOffline?): Promise<void> {
    const db = await this.dbPromise;
    const tx = db.transaction(storeName ? storeName : 'data', 'readwrite');
    if (fromOffline) {
      tx.objectStore(storeName ? storeName : 'data').put(value, key);
    } else {
      tx.objectStore(storeName ? storeName : 'data').put(JSON.stringify(value), key);
    }

    await tx.done;
  }

  // add item to a store 
  async addItem(value: any, storeName?, storageShare?): Promise<void> {
    const db = await this.dbPromise;
    const tx = db.transaction(storeName, 'readwrite');
    value.forEach(element => {
      tx.objectStore(storeName).put(element, storageShare ? storageShare : element.id);
    });

    await tx.done;
  }

  async getAllItems(storeName?): Promise<any> {
    try {
      const db = await this.dbPromise;

      // Using a single transaction to read from the store
      const tx = db.transaction(storeName, 'readonly');
      const store = tx.objectStore(storeName);

      const value = await store.getAll();

      tx.oncomplete; // Ensuring the transaction is completed

      return value || null;
    } catch (error) {
      console.error('Failed to get all items:', error);
      return null;
    }
  }

  getAllSyncItems(storeName?): any {
    this.dbPromise.then((res) => {
      const tx = res.transaction(storeName ? storeName : 'data', 'readonly');
      const value = tx.objectStore(storeName ? storeName : 'data').getAll().then(all => {
        return all;
      });
    })
  }
  async getItem(key, storeName?, addedOffline?): Promise<any> {
    const db = await this.dbPromise;
    const tx = db.transaction(storeName ? storeName : 'data', 'readonly');
    const value = await tx.objectStore(storeName ? storeName : 'data')?.get(key);
    if (value) {
      // return addedOffline ? value : JSON.parse(value);
      return value;
    } else {
      return null
    }

  }

  async removeItem(key: string, storeName?): Promise<void> {
    const db = await this.dbPromise;
    const tx = db.transaction(storeName ? storeName : 'data', 'readwrite');
    tx.objectStore(storeName ? storeName : 'data').delete(key);
    await tx.done;
  }

  async clear(storeName?): Promise<void> {
    const db = await this.dbPromise;
    const tx = db.transaction(storeName, 'readwrite');
    tx.objectStore(storeName).clear();
    await tx.done;
  }

  filterDataByOfflineId(storeName, offlineId): any[] {
    return [];
  }

  async updateDocsOfflineIdsBeforeSync(storeName, offlineId, id) {
    const res = await this.getAllItems(storeName);
    res.then(res => {
      if (res && res.length > 0) {
        res.forEach(element => {
          if (element.clientId === offlineId) {
            element.clientId = id;
          }
        });
      }
      return res;
    })
  }

  async clearAllData() {
    const db = await this.dbPromise;
    const transaction = db.transaction(db.objectStoreNames, 'readwrite');
    for (const storeName of db.objectStoreNames) {
      const store = transaction.objectStore(storeName);
      store.clear();
    }
    return transaction.done;
  }

}