import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { map, catchError } from 'rxjs/operators';
import { StorageUtils } from '../shared/storage-utils';


@Injectable({
    providedIn: 'root',
})

export class AuthGuard implements CanActivate, CanActivateChild {
    constructor(private authService: AuthService, private router: Router) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
        // tslint:disable-next-line: max-union-size tslint:disable-next-line: use-type-alias
    ): | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        const roles =
            next.data && next.data.roles ? next.data.roles : undefined;
        if (next.data && next.data.page && next.data.page === 'notConnect') {
            return this.notAuthenticatedConditions();
        } else {
            return this.authenticatedConditions(roles);
        }
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
        // tslint:disable-next-line: max-union-size
    ): | boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (
            childRoute.data &&
            childRoute.data.page &&
            childRoute.data.page === 'notConnect'
        ) {
            return this.notAuthenticatedConditions();
        } else {
            const roles =
                childRoute.data && childRoute.data.roles
                    ? childRoute.data.roles
                    : undefined;
            return this.authenticatedConditions(roles);
        }
    }

    authenticatedConditions(
        roles: string[]
        // tslint:disable-next-line: max-union-size
    ): | boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authService.isAuthenticated()) {
            return true;
        }
        const token = StorageUtils.getAuthToken();
        if (token) {
            return this.authService.checkAuthentication().pipe(
                map(res => {
                    // is logged in so can access the component
                    this.authService.setAuthenticated(true);
                    StorageUtils.setUser(res);
                    // check if user has roles to access route
                    return true;
                }),
                catchError(error => {
                    StorageUtils.removeAuthToken();
                    StorageUtils.removeUser();
                    this.router.navigate(['login']);
                    return of(false);
                })
            );
        } else {
            this.router.navigate(['login']);
            return false;
        }
    }
    // tslint:disable-next-line: max-union-size
    notAuthenticatedConditions(): | boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authService.isAuthenticated()) {
            this.router.navigate(['']);
            return false;
        }
        const token = StorageUtils.getAuthToken();
        if (token) {
            return this.authService.checkAuthentication().pipe(
                map(res => {
                    // is logged in so can't access the component
                    this.authService.setAuthenticated(true);

                    // redirect to home
                    this.router.navigate(['']);
                    return false;
                }),
                catchError(error => {
                    return of(true);
                })
            );
        } else {
            return true;
        }
    }

}