import { SpinnerVisibilityService } from 'ng-http-loader';
import { NavbarModule } from './../layout/components/navbar/navbar.module';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NavigationStart, Router } from '@angular/router';
import { FuseConfigService } from '@fuse/services/config.service';
import { FuseConfig } from '@fuse/types';
import { TranslateService } from '@ngx-translate/core';
import { NotifiersService } from './notifiers.service';
import * as html2pdf from 'html2pdf.js';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as fr } from '../navigation/i18n/fr';
import { locale as dz } from '../navigation/i18n/dz';
import { StorageUtils } from 'app/shared/storage-utils';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { HttpParams } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class CommonService {
  successMessage = 'success-message-snackbar';
  errorMessage = 'error-snackbar';
  infoMessage = 'info-message-snackbar';

  constructor(
    private translate: TranslateService,
    private snackBar: MatSnackBar,
    private router: Router,
    private _fuseSidebarService: FuseSidebarService,
    private notifiersService: NotifiersService,
    private fuseConfigService: FuseConfigService,
    private spinnerService: SpinnerVisibilityService,
    private fuseLoaderService: FuseTranslationLoaderService
  ) {
    this.fuseLoaderService.loadTranslations(fr, dz);
    router.events.forEach((event) => {
      if (
        event instanceof NavigationStart && event.url !== this.router.url &&
        !(this.router.url.split('/')[this.router.url.split('/').length - 1].includes('add') ||
          this.router.url.split('/')[this.router.url.split('/').length - 1].includes('new') ||
          this.router.url.split('/')[this.router.url.split('/').length - 1].includes('edit'))
      ) {
        snackBar.dismiss();
      }
    });
  }

  showSnackBar(message, panelClassParam, disableTranslate?) {
    const msg = message.split('/');
    if (msg.length > 1) { // if starting numbers errors > 1
      message = this.translate.instant('errors.startNumber');
      // get error code without errors.
      msg[0]= msg[0].split('.')[1];
      msg.forEach(element => {
        message = message + this.translate.instant('errors.' + element) + ',';
      });
      this.snackBar.open(
        message,
        'X',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: [panelClassParam], // .success-message-snackbar .error-snackbar .info-message-snackbar
        }
      );
    } else if (msg[0].indexOf('_') === 11 ){
      // for starting numbers errors
      this.snackBar.open(this.translate.instant('errors.startNumber') + this.translate.instant(message),
        'X',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: [panelClassParam], // .success-message-snackbar .error-snackbar .info-message-snackbar
        }
      );
    } else {
      // for all others errors
      this.snackBar.open(
        disableTranslate ? message : this.translate.instant(message),
        'X',
        {
          horizontalPosition: 'center',
          verticalPosition: 'top',
          panelClass: [panelClassParam], // .success-message-snackbar .error-snackbar .info-message-snackbar
        }
      );
    }
  }

  showInfoFiscaleSnackBar(info, type) {
    let data = this.translate.instant('errors.infoText');
    if (!info.commercialRegisterNumber) {
      data = data + this.translate.instant('errors.commercialRegisterNumber');
    }

    if ((type === 'folder' && !info.taxationArticle) || (type === 'client' && !info.taxItemNumber)) {
      data = data + this.translate.instant('errors.taxationArticle');
    }
    if ((type === 'folder' && !info.nis) || (type === 'client' && !info.taxIdentificationNumber)) {
      data = data + this.translate.instant('errors.nis');
    }

    if ((type === 'folder' && !info.nif) || (type === 'client' && !info.statisticalIdentificationNumber)) {
      data = data + this.translate.instant('errors.nif');
    }
    const message = type === 'folder' ? this.translate.instant('errors.infoFolderText') :
      this.translate.instant('errors.infoClientText');
    data = data.substring(0, data.length - 2) + message;
    this.showSnackBar(data, 'info-message-snackbar', true);
  }
  /**
   * check info fiscale of company
   * @param info 
   * @returns 
   */
  checkCompanyInfoFiscalInformation(info) {
    if (!info.commercialRegisterNumber || !info.taxationArticle || !info.nis || !info.nif) {
      return false;
    } else {
      return true;
    }
  }
  /**
   * Check info fiscale of client
   * @param info 
   * @returns 
   */
  checkClientFiscalInformation(info) {
    if (!info.commercialRegisterNumber || !info.taxItemNumber
      || !info.taxIdentificationNumber || !info.statisticalIdentificationNumber) {
      return false;
    } else {
      return true;
    }
  }
  checkClientFiscalInfo(info) {
    if (!info.clientCommercialRegisterNumber || !info.clientTaxationArticle
      || !info.clientNif || !info.clientNis) {
      return false;
    } else {
      return true;
    }
  }

  showImportSnackBar(message1, message2, message3, variable1, variable2, variable3, panel) {
    const m = this.translate.instant(message1);
    const m2 = this.translate.instant(message2);
    const m3 = this.translate.instant(message3);
    this.snackBar.open(m + ' ' + variable1 + '. ' + variable2 + ' ' + m2 + ' ' + variable3 + ' ' + m3, 'X',
      {
        horizontalPosition: 'center',
        verticalPosition: 'top',
        panelClass: [panel]
      });
  }

  // showSnackBarDetails(datas, panel) {
  //   this.snackBar.openFromComponent(SnackBarComponent, {
  //     horizontalPosition: 'center',
  //     verticalPosition: 'top',
  //     panelClass: [panel],
  //     data: datas
  //   });
  // }
  /**
   * Convert base64 to blob file
   * @param base64 the base64 string
   * @returns a blob File
   */
  base64ToBlobFile(base64: any): any {
    const byteString = window.atob(base64);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    // tslint:disable-next-line: prefer-immediate-return
    const blob = new Blob([int8Array], { type: 'application/pdf' });
    return blob;
  }

  closeNavbar() {
    if (this._fuseSidebarService.getSidebar('navbar') && this._fuseSidebarService.getSidebar('navbar').opened) {
      this._fuseSidebarService.getSidebar('navbar').fold();
      this._fuseSidebarService.getSidebar('navbar').onMouseLeave();
      this.notifiersService.refreshDatatable();
    }
  }

  currencyFormat(num, pos?) {
    if (num) {
      if (!pos) { pos = 2; }
      return (Math.round(num * Math.pow(10, pos)) / Math.pow(10, pos)).toFixed(2).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
    } else { return 0; }
  }

  getChange(): string {
    return 'DA'
  }


  hideNavbar(status) {
    const conf: FuseConfig = this.fuseConfigService.defaultConfig;
    conf.layout.navbar.hidden = status;
    this.fuseConfigService.setConfig(conf);
  }

  downloadPdf(doc, docsId?, fromProvider?) {
    this.spinnerService.show();
    const fileName = doc.name;
    const footer = document.getElementById('footer-document');
    if (footer) {
      footer.style.display = 'none';
    }
    const element = docsId ? document.getElementById('grouped-docs-print').innerHTML : document.getElementById('docs-print').innerHTML;
    const options = {
      margin: [12, 5, 12, 5],
      filename: fileName,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { dpi: 192, scale: 4, letterRendering: true, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: ['css', 'legacy'] }
    };
    // do not delete (this is fix for text misplaced in pdf generated)
    document.body.style.display = 'unset';
    html2pdf().from(element).set(options).toPdf().get('pdf').then((pdf) => {
      const totalPage = pdf.internal.getNumberOfPages();

      // set footer page informations
      for (let i = 1; i <= totalPage; i++) {
        pdf.setPage(i);
        //this.setFooterPage(pdf, doc, fromProvider);
      }
      // do not delete (this reset body display after pdf generated)
      document.body.style.display = 'flex';
      if (footer) {
        footer.style.display = 'flex';
      }
      this.spinnerService.hide();
    }
    ).save();
  }
  /**
   * Set footer page fiscale information
   * @param pdf 
   * @param doc 
   */
  setFooterPage(pdf, doc, formProvider) {
    pdf.setLineWidth(0);
    pdf.line(5, 287, 207, 287); //(start, equal, end, equal)
    pdf.setFontSize(8);
    pdf.setFontType('bold');
    pdf.text('N° NIS:', 5, 290);
    pdf.text('N° NIF:', 48, 290);
    pdf.text('N° RIB:', 88, 290);
    pdf.text('N° Registre de commerce:', 143, 290);

    pdf.setFontSize(8);
    pdf.setFontType('normal');
    pdf.text(doc.companyNis ? doc.companyNis : '/', 15, 290);
    pdf.text(doc.companyNif ? doc.companyNif : '/', 58, 290);
    pdf.text(doc.companyBankAccountNumber ? doc.companyBankAccountNumber : '/', 98, 290);
    pdf.text(doc.companyCommercialRegisterNumber ? doc.companyCommercialRegisterNumber : '/', 178, 290);

  }

  resetLocalStorage() {
    const selectedFolder = StorageUtils.getSelectedFolder();
    const selectedAccountId = StorageUtils.getSelectedAccountId();
    const selectedcontext = StorageUtils.getContext();
    localStorage.clear();
    sessionStorage.clear();
    StorageUtils.setSelectedFolder(selectedFolder);
    StorageUtils.setSelectedAccountId(selectedAccountId);
    StorageUtils.setContext(selectedcontext);
  }
  //set fiscal control to null in empty string case
  onFiscalValuechange(event, control: any) {
    control.setValue((event.target.value).toUpperCase())
    if (!event.target.value) {
      control.setValue(null)
    }
  }

  getLegalFormName(legalFormId) {
    let name = '';
    if (legalFormId) {
      const legalFormName = StorageUtils.getLegalForms().find(form => form.id === legalFormId).name;
      if (legalFormName.indexOf('-') !== -1) {
        name = legalFormName.substring(0, legalFormName.indexOf('-')) + ' ';
      } else {
        name = legalFormName.substring(legalFormName.indexOf('-') + 1) + ' ';
      }
    }
    return name;
  }
  getQueryParams(params) {
    let queryParms = new HttpParams();
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        queryParms = queryParms.append(key, value + '');
      }
    }
    return queryParms;
  }
}
