export class StorageUtils {
    static selectedFolderId: any;

    static setSelectedClientAccountId(clientAccount) {
        localStorage.setItem('clientAccount', JSON.stringify(clientAccount));
        sessionStorage.setItem('clientAccount', JSON.stringify(clientAccount));
    }

    static getSelectedClientAccountId(): any {
        const clientAccountId = localStorage.getItem('clientAccount');
        if (clientAccountId) {
            const client = JSON.parse(clientAccountId);
            return client.id;
        } else {
            return null;
        }
    }
    static setAuthToken(item: any) {
        localStorage.setItem('access_token', JSON.stringify(item));
    }

    static getAuthToken(): any {
        const itemString = localStorage.getItem('access_token');
        if (itemString) {
            return JSON.parse(itemString);
        } else {
            return null;
        }
    }

    static removeAuthToken() {
        localStorage.removeItem('access_token');
    }

    static setUser(item: any) {
        localStorage.setItem('user', JSON.stringify(item));
    }

    // tslint:disable-next-line:no-identical-functions
    static getUser(): any {
        const itemString = localStorage.getItem('user');
        if (itemString) {
            return JSON.parse(itemString);
        } else {
            return null;
        }
    }

    static setCompanyPermission(company: any) {
        localStorage.setItem('companyPermissions', JSON.stringify(company));
    }

    static getCompanyPermissions(): any {
        const items = localStorage.getItem('companyPermissions');
        if (items) {
            return JSON.parse(items);
        } else {
            return null;
        }
    }

    static getSavQuote(): any {
        const items = localStorage.getItem('savQuote');
        if (items) {
            return JSON.parse(items);
        } else {
            return null;
        }
    }

    static setSavQuote(item: any) {
        localStorage.setItem('savQuote', JSON.stringify(item));
    }

    static removeSavQuote() {
        localStorage.removeItem('savQuote');
    }

    static removeCompanyPermission() {
        localStorage.removeItem('companyPermissions');
    }

    static removeUser() {
        localStorage.removeItem('user');
    }
    static removeSelectedFolderId() {
        localStorage.removeItem('selectedFolderId');
        sessionStorage.removeItem('selectedFolderId');
    }
    static removeSelectedFolder() {
        localStorage.removeItem('selectedFolder');
        sessionStorage.removeItem('selectedFolder');
    }
    static getSelectedFolderId(): any {
        const fId = StorageUtils.getSelectedFolder() ? StorageUtils.getSelectedFolder() : null;
        if (fId) {
            return JSON.parse(fId.id);
        } else {
            return null;
        }
    }
    static getSelectedFolder(): any {
        let folder;
        if (localStorage.getItem('updatedFolder') || !sessionStorage.getItem('selectedFolder')) {
            folder = localStorage.getItem('selectedFolder');
            this.setSelectedFolder(JSON.parse(folder));
        } else {
            folder = sessionStorage.getItem('selectedFolder');
        }
        if (folder) {
            return JSON.parse(folder);
        } else {
            return null;
        }
    }
    static setSelectedFolder(folder, refresh?) {
        localStorage.setItem('selectedFolder', JSON.stringify(folder));
        if (refresh) {
            localStorage.setItem('updatedFolder', JSON.stringify(refresh));
        }
        sessionStorage.setItem('selectedFolder', JSON.stringify(folder));
    }

    static removeSelectedAccountId() {
        localStorage.removeItem('selectedAccountId');
        sessionStorage.removeItem('selectedAccountId');
    }
    static getSelectedAccountId(): any {
        let accountId = sessionStorage.getItem('selectedAccountId');

        if (!accountId) {
            accountId = localStorage.getItem('selectedAccountId');
        }

        if (accountId) {
            return JSON.parse(accountId);
        } else {
            return null;
        }
    }
    static setSelectedAccountId(accountId) {
        localStorage.setItem('selectedAccountId', JSON.stringify(accountId));
        sessionStorage.setItem('selectedAccountId', JSON.stringify(accountId));
    }

    static removeContext() {
        localStorage.removeItem('context');
        sessionStorage.removeItem('context');
    }
    static getContext(): any {
        let context = sessionStorage.getItem('context');

        if (!context) {
            context = localStorage.getItem('context');
        }

        if (context) {
            return JSON.parse(context);
        } else {
            return null;
        }
    }
    static setContext(context) {
        localStorage.setItem('context', JSON.stringify(context));
        sessionStorage.setItem('context', JSON.stringify(context));
    }

    static setClientAccount(accounts: any) {
        localStorage.setItem('accounts', JSON.stringify(accounts));
    }

    static getClientAccount(): any {
        const account = localStorage.getItem('accounts');
        if (account) {
            return JSON.parse(account);
        } else {
            return null;
        }
    }
    /** set permission */
    static setReadWritePermission(read: any) {
        localStorage.setItem('readWritePermission', read);
    }
    /** get permission */
    static getReadWritePermission(): any {
        const readWrite = localStorage.getItem('readWritePermission');
        if (readWrite) {
            return JSON.parse(readWrite);
        } else {
            return null;
        }
    }

    static removeCountries() {
        localStorage.removeItem('countries');
    }


    static setLang(item: any) {
        localStorage.setItem('lang', JSON.stringify(item));
    }

    // tslint:disable-next-line:no-identical-functions
    static getLang(): any {
        const itemString = localStorage.getItem('lang');
        if (itemString) {
            return JSON.parse(itemString);
        } else {
            return 'fr';
        }
    }


    static setPrivilege(item) {
        localStorage.setItem('privilege', JSON.stringify(item));
    }

    static getPrivilege(): any {
        const privilege = localStorage.getItem('privilege');
        if (privilege) {
            return JSON.parse(privilege);
        } else {
            return null;
        }
    }
    //wilaya
    static setWilayas(wilayas: any) {
        localStorage.setItem('wilayas', JSON.stringify(wilayas));
    }
    static getWilayas(): any {
        const wilayasString = localStorage.getItem('wilayas');
        if (wilayasString) {
            return JSON.parse(wilayasString);
        } else {
            return [];
        }
    }

    static removeWilayas() {
        localStorage.removeItem('wilayas');
    }
    //communes
    static setCommunes(communes: any) {
        localStorage.setItem('communes', JSON.stringify(communes));
    }

    static getCommunes(): any {
        const communesString = localStorage.getItem('communes');
        if (communesString) {
            return JSON.parse(communesString);
        } else {
            return [];
        }
    }

    static removeCommunes() {
        localStorage.removeItem('communes');
    }
    // CommunesByWilaya
    static setCommunesByWilaya(communesByWilaya: any) {
        localStorage.setItem('communesByWilaya', JSON.stringify(communesByWilaya));
    }

    static getCommunesByWilaya(): any {
        const communesByWilayaString = localStorage.getItem('communesByWilaya');
        if (communesByWilayaString) {
            return JSON.parse(communesByWilayaString);
        } else {
            return [];
        }
    }
    static removeCommunesByWilaya() {
        localStorage.removeItem('communesByWilaya');
    }

    // legal forms
    static setLegalForms(LegalForms: any) {
        localStorage.setItem('LegalForms', JSON.stringify(LegalForms));
    }

    static getLegalForms(): any {
        const LegalForms = localStorage.getItem('LegalForms');
        if (LegalForms) {
            return JSON.parse(LegalForms);
        } else {
            return [];
        }
    }
    static removeLegalForms() {
        localStorage.removeItem('LegalForms');
    }

    // activities
    static setActivities(activities: any) {
        localStorage.setItem('activities', JSON.stringify(activities));
    }
    static getActivities(): any {
        const activities = localStorage.getItem('activities');
        if (activities) {
            return JSON.parse(activities);
        } else {
            return [];
        }
    }

    static removeActivities() {
        localStorage.removeItem('activities');
    }

    static setEmptyFolder(isEmpty: any) {
        localStorage.setItem('noFolder', isEmpty);

    }

    static getEmptyFolder(): any {
        const noFolder = localStorage.getItem('noFolder');
        if (noFolder) {
            return noFolder;
        } else {
            return null;
        }
    }

    // payment terms
    static setPayementTerms(payementTerms: any) {
        localStorage.setItem('payementTerms', JSON.stringify(payementTerms));
    }

    static getPayementTerm(): any {
        const payementTerms = localStorage.getItem('payementTerms');
        if (payementTerms) {
            return JSON.parse(payementTerms);
        } else {
            return [];
        }
    }
    static removePayementTerms() {
        localStorage.removeItem('payementTerms');
    }

    static setTva(tva: any) {
        localStorage.setItem('tva', JSON.stringify(tva));
    }

    static getTva(): any {
        const tva = localStorage.getItem('tva');
        if (tva) {
            return JSON.parse(tva);
        } else {
            return [];
        }
    }

    static removeTva() {
        localStorage.removeItem('tva');
    }

    static setUnitMeasure(unit: any) {
        localStorage.setItem('unitMeasure', JSON.stringify(unit));
    }

    static getUnitMeasure() {
        const unit = localStorage.getItem('unitMeasure');
        if (unit) {
            return JSON.parse(unit);
        } else {
            return [];
        }
    }

    static removeUnitMeasure() {
        localStorage.removeItem('unitMeasure');
    }

    static setAssistanceValue(value) {
        localStorage.setItem('assistance', JSON.stringify(value));
    }

    static getAssistanceValue() {
        return JSON.parse(localStorage.getItem('assistance'));
    }
}
