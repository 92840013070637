import { Injectable } from '@angular/core';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

import { Workbook } from 'exceljs';

import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { DataStorageService } from './data.service';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ExportService {
  EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  EXCEL_EXTENSION = '.xlsx';
  PDF_EXTENSION = '.pdf';
  EXCEL_TYPE_CHARTSET = 'text/xls;charset=utf-8'
  TYPE_FORMAT = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  productUrl = environment.ENDPOINTS.PRODUCTS_URL;
  companiesUrl = environment.ENDPOINTS.COMPANIES_URL;
  quoteUrl = environment.ENDPOINTS.QUOTE_URL;
  deliveryUrl = environment.ENDPOINTS.DELIVERY_NOTE_URL;
  invoiceUrl = environment.ENDPOINTS.INVOICE_URL;

  constructor(private httpClient: HttpClient, private cacheStorageService: DataStorageService) { }


  /* products*/
  /*- export profucts template */
  exportTemplateProduct() {
    const header = ['Référence', 'Designation', 'Description', 'Code barres', 'Quantité en stock ', 'Unité', 'Tva', 'PMP',
      'Prix HT'];

    const data = ['001', 'Designation1', 'descriptoin1', '65833254', '100', 'pce', '19', '25', '150'];
    const data2 = ['002', 'Designation2', 'descriptoin2', '65833254', '100', 'pce', '09', '25', '150'];

    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Feuille 1');//add XLs sheet 
    const headerRow = worksheet.addRow(header);//add header tp XLs sheet 
    //header configuration
    headerRow.eachCell((cell, rowNum) => {
      if (rowNum === 1 || rowNum === 2 || rowNum === 5 || rowNum === 9 || rowNum === 10) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFF0000' },

        };
      } else {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFFFFFFF' },

        };
      }
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    //add row after header
    worksheet.addRow(data);
    worksheet.addRow(data2);
    //collumns configuration
    worksheet.columns.forEach(column => {
      let maxLength = 0;
      column['eachCell']({ includeEmpty: true }, cell => {
        const columnLength = cell.value ? cell.value.toString().length : 25;
        if (columnLength > maxLength) {
          maxLength = columnLength;
        }
      });
      column.width = maxLength < 10 ? 25 : maxLength;
    });
    //rows configuration
    worksheet.eachRow({ includeEmpty: true }, row => {
      row.eachCell({ includeEmpty: true }, cell => {
        cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
      });
    });

    workbook.xlsx.writeBuffer().then(datas => {
      const blob = new Blob([datas], { type: this.TYPE_FORMAT });
      FileSaver.saveAs(blob, 'template_import_products.xlsx');
    });
  }
  exportProduct(companyId: number, search: any, familyId?: any, active?) {
    const searchParam = search ? `?search=${search}` : `?search=`;
    const filterId = familyId ? `&familyId=${familyId}` : `&familyId=`;
    const status = active && active.length === 1 ? `&active=${active}` : '';
    const req = this.productUrl + `/${companyId}/products/export${searchParam}` + filterId + status;
    // return this.httpClient.get<any>(req, { observe: 'response', responseType: 'blob' as 'json' });
    if (!navigator.onLine) {
      return from(this.cacheStorageService.getItem(req)).pipe(
        catchError(() => this.httpClient.get<any>(req, { observe: 'response', responseType: 'blob' as 'json' }))
      );
    }
    return this.httpClient.get<any>(req, { observe: 'response', responseType: 'blob' as 'json' }).pipe(
      switchMap(response => {
        this.cacheStorageService.setItem(req, response);
        return of(response);
      })
    );

  }
  importProduct(companyId, file: any) {
    const req = this.productUrl + `/${companyId}/product-imports`;
    return this.httpClient.post<any>(req, file);

  }

  importSearialNumbers({ companyId, barCode, file, depositId, productId, locationId, type }: { companyId: number, barCode?: any, file: any, depositId?: number, productId?: number, locationId?: number, type: String }) {
    let req: string = '';
    switch (type) {
      case 'ENTRY_VOUCHER':
        req = this.productUrl + `/${companyId}/product-line-import/barcode-serial-number/barcode/${barCode}`;
        break;
      case 'EXIT_VOUCHER':
      case 'TRANSFER_VOUCHER':
        req = this.productUrl + `/${companyId}/product-line-import/serial-number/product/${productId}/location/${locationId}`;
        // req = "https://run.mocky.io/v3/d1ba9eac-dc8b-4ce7-a99b-3e249ad91ef7";
        break;
      case 'DELIVERY_NOTE':
      case 'INVOICE':
        req = this.productUrl + `/${companyId}/product-line-import/serial-number/product/${productId}`;
        // req = "https://run.mocky.io/v3/5dfcaf0b-ce9e-4246-9122-1024c041d738";

        break;
    }

    return this.httpClient.post<any>(req, file);

  }

  importGlobalSearialNumbers({ companyId, file, depositId, type }: { companyId, file: any, depositId?: number, type: String }) {
    let req: string = '';
    switch (type) {
      case 'ENTRY_VOUCHER':
        req = this.productUrl + `/${companyId}/product-line-import/barcode-serial-number`;
        break;
      case 'EXIT_VOUCHER':
      case 'TRANSFER_VOUCHER':
        req = this.productUrl + `/${companyId}/product-line-import/serial-number/deposit/${depositId}`;
        // req = "https://run.mocky.io/v3/3ce205f5-0b17-4901-a4f4-4c2556db12ab";


        break;
      case 'DELIVERY_NOTE':
      case 'INVOICE':
        req = this.productUrl + `/${companyId}/product-line-import/serial-number`;
        // req = "https://run.mocky.io/v3/8f3ef30e-7596-4933-a17d-9343919aa913";

        break;
    }

    return this.httpClient.post<any>(req, file);
  }

  importOrderVoucherFile({ companyId, orderVoucherId, file, removeAttachedFile }: { companyId: any, orderVoucherId: any, file: FormData, removeAttachedFile: boolean }) {
    const remove = removeAttachedFile != null ? '?removeAttachedFile=' + removeAttachedFile : '';
    const req = this.productUrl + `/${companyId}/purchase-orders/${orderVoucherId}/update-attached-file` + remove;
    return this.httpClient.put<any>(req, file ?? {});
  }

  downloadImportProductsRapport(companyId: number, rapportId: number) {
    const req = this.productUrl + `/${companyId}/product-imports/${rapportId}`
    return this.httpClient.get<any>(req, { observe: 'response', responseType: 'blob' as 'json' });
  }

  setParameters(advencedFilter) {
    const params = { ...advencedFilter };
    let queryParms = new HttpParams();
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        queryParms = queryParms.append(key, value + '');
      }
    }
    return queryParms;
  }

  exportQuote(companyId: number, advencedFilter: any, status?, sort?: string, dir?: string, isSav: boolean = false) {
    const req = this.companiesUrl + `/${companyId}/${isSav ? 'sav-quotes' : 'quotes'}/export`;
    const statusParam = status ? `?statuses=${status}` : `?statuses=`;
    const sortParam = sort ? `&sort=${sort},${dir}` : `&sort=`
    return this.httpClient.get<any>(req + statusParam + sortParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }

  exportDelivery(companyId: number, advencedFilter: any, status?, sort?, dir?: string) {
    const req = this.companiesUrl + `/${companyId}/delivery-notes/export`;
    const statusParam = status ? `?statuses=${status}` : `?statuses=`;
    const sortParam = sort ? `&sort=${sort},${dir}` : `&sort=`
    return this.httpClient.get<any>(req + statusParam + sortParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }

  exportInvoice(companyId: number, advencedFilter: any, status?, paymentFilters?, sort?, dir?: string, isSav: boolean = false) {
    const req = this.companiesUrl + `/${companyId}/${isSav ? 'sav-invoices' : 'invoices'}/export`;
    const statusParam = status ? `?statuses=${status}` : `?statuses=`;
    const sortParam = sort ? `&sort=${sort},${dir}` : `&sort=`
    const paymentFiltersParam = paymentFilters ? `&paymentStatus=${paymentFilters}` : `&paymentStatus=`;
    return this.httpClient.get<any>(req + statusParam + paymentFiltersParam + sortParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }

  exportReturnVouchers(companyId: number, advencedFilter: any, status?, sort?, dir?: string) {
    const req = this.companiesUrl + `/${companyId}/return-vouchers/export`;
    const statusParam = status ? `?statuses=${status}` : `?statuses=`;
    const sortParam = sort ? `&sort=${sort},${dir}` : `&sort=`
    return this.httpClient.get<any>(req + statusParam + sortParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }

  exportReceiptVouchers(companyId: number, advencedFilter: any, sort?, dir?: string) {
    const req = this.companiesUrl + `/${companyId}/receipt-vouchers/export`;
    const sortParam = sort ? `?sort=${sort},${dir}` : `?sort=`
    return this.httpClient.get<any>(req + sortParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }
  exportBdcProvider(companyId: number, advencedFilter: any, sort?, dir?: string) {
    const req = this.companiesUrl + `/${companyId}/supplier-purchase-orders/export`;
    const sortParam = sort ? `?sort=${sort},${dir}` : `?sort=`
    return this.httpClient.get<any>(req + sortParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }

  exportProviderInvoices(companyId: number, advencedFilter: any, sort?, dir?: string) {
    const req = this.companiesUrl + `/${companyId}/supplier-invoices/export`;
    const sortParam = sort ? `?sort=${sort},${dir}` : `?sort=`
    return this.httpClient.get<any>(req + sortParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }

  exportReturnProvider(companyId: number, advencedFilter: any, status?, sort?, dir?: string) {
    const req = this.companiesUrl + `/${companyId}/supplier-return-orders/export`;
    const statusParam = status ? `?statuses=${status}` : `?statuses=`;
    const sortParam = sort ? `&sort=${sort},${dir}` : `&sort=`
    return this.httpClient.get<any>(req + statusParam + sortParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }

  exportAssets(companyId: number, advencedFilter: any, status?, sort?, dir?: string) {
    const req = this.companiesUrl + `/${companyId}/credit-notes/export`;
    const statusParam = status ? `?statuses=${status}` : `?statuses=`;
    const sortParam = sort ? `&sort=${sort},${dir}` : `&sort=`
    return this.httpClient.get<any>(req + statusParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }

  exportOrderVoucher(companyId: number, advencedFilter: any, status?, sort?: string, dir?: string) {
    const req = this.companiesUrl + `/${companyId}/purchase-orders/export`;
    const statusParam = status ? `?statuses=${status}` : `?statuses=`;
    const sortParam = sort ? `&sort=${sort},${dir}` : ``
    return this.httpClient.get<any>(req + statusParam + sortParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }
  /* clients*/
  /** export client template */
  exportTemplateClients(companyId: number) {
    const req = this.productUrl + `/${companyId}/clients/export_template`
    return this.httpClient.get<any>(req, { observe: 'response', responseType: 'blob' as 'json' });
  }
  exportErrorReport(companyId: number) {
    const req = this.productUrl + `/${companyId}/client-imports/repport`
    return this.httpClient.get<any>(req, { observe: 'response', responseType: 'blob' as 'json' });
  }
  exportClients(companyId: number, active?: any, advncedFilters?: any) {
    active = active && active.length === 1 ? active[0] + '' : null;
    const params = { active, ...advncedFilters };
    let queryParms = new HttpParams();
    for (const [key, value] of Object.entries(params)) {
      if (value) {
        queryParms = queryParms.append(key, value + '');
      }
    }
    const req = this.companiesUrl + `/${companyId}/clients/export`;
    return this.httpClient.get<any>(req, { observe: 'response', params: queryParms, responseType: 'blob' as 'json' });

  }
  importClients(companyId: number, file: any) {
    const req = this.companiesUrl + `/${companyId}/client-imports`;//a modifier
    return this.httpClient.post(req, file);
  }


  downloadClientsRapport(companyId: number, rapportId: number) {
    const req = this.productUrl + `/${companyId}/client-imports/repport`
    return this.httpClient.get<any>(req, { observe: 'response', responseType: 'blob' as 'json' });
  }
  switchClientRequiredFields(fields): any {
    const requiredFields = [];
    fields.forEach(value => {
      switch (value) {
        case ' company':
          requiredFields.push('raison sociale');
          break;
        case 'lastName':
          requiredFields.push('prénom');
          break;
        case 'firstName':
          requiredFields.push('nom');
          break;
        case 'legalForm':
          requiredFields.push('fome juridique');
          break;
        default:
          break;
      }
    });
    //reference,name,quantityInStock,preTaxPrice,measureUnitName,taxValue  to do 
    return requiredFields.toString();
  }
  switchProductRequiredFields(fields): any {
    const requiredFields = [];
    fields.forEach(value => {
      switch (value) {
        case 'reference':
          requiredFields.push('référence');
          break;
        case 'name':
          requiredFields.push('Designation');
          break;
        case 'quantityInStock':
          requiredFields.push('Quantité en stock ');
          break;
        case 'preTaxPrice':
          requiredFields.push('Prix HT');
          break;
        case 'measureUnitName':
          requiredFields.push('Unité');
          break;
        case 'taxValue':
          requiredFields.push('Tva');
          break;
        default:
          break;
      }
    });
    return requiredFields.toString();
  }

  /* Treasury*/
  exportCashing(companyId: number, advencedFilter: any, status?, sort?: string, dir?: string) {
    const req = this.companiesUrl + `/${companyId}/cash-receipts/export`;
    const statusParam = status ? `?statuses=${status}` : `?statuses=`;
    const sortParam = sort ? `&sort=${sort},${dir}` : `&sort=`
    return this.httpClient.get<any>(req + statusParam + sortParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }
  exportDisbursment(companyId: number, advencedFilter: any, status?, sort?: string, dir?: string) {
    const req = this.companiesUrl + `/${companyId}/disbursements/export`;
    const statusParam = status ? `?statuses=${status}` : `?statuses=`;
    const sortParam = sort ? `&sort=${sort},${dir}` : `&sort=`
    return this.httpClient.get<any>(req + statusParam + sortParam, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }

  /* Statistics*/
  exportSalesStatistics(companyId: number, type, dateFilter, clientId, caisses, productsId, sav, technicians, onlyFreeProductsValue, familyClients: number[]) {
    const req = this.companiesUrl + `/${companyId}/products-in-invoices/export`;
    const exportType = type ? `?type=${type}` : null;
    const exportStartDate = dateFilter && dateFilter.dateStart ? `&createdAfter=${dateFilter.dateStart}` : ``;
    const exportEndDate = dateFilter && dateFilter.dateEnd ? `&createdBefore=${dateFilter.dateEnd}` : ``;
    const exportClientId = clientId && clientId.length > 0 ? `&clientIds=${clientId}` : ``;
    const exportCaisse = caisses && caisses.length > 0 ? `&cashDeskNames=${caisses}` : ``;
    const productsIds = productsId && productsId.length > 0 ? `&productNames=${productsId}` : ``;
    const savTypes = sav && sav.length > 0 ? `&documentSection=${sav}` : ``;
    const exportTechnicianIds = technicians && technicians.length > 0 ? `&technicianIds=${technicians}` : ``;
    const onlyFreeProducts = `&onlyFreeProducts=${onlyFreeProductsValue}`;
    const exportFamilyClients = familyClients && familyClients.length > 0 ? `&familyId=${familyClients.join(',')}` : '';

    const advencedFilter = null;
    return this.httpClient.get<any>(req + exportType + exportEndDate + exportStartDate + exportClientId + exportCaisse + productsIds + savTypes + exportTechnicianIds + onlyFreeProducts + exportFamilyClients, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }
  exportStocksStatistics(companyId: number, type, dateFilter, productsId, operations) {
    const req = this.companiesUrl + `/${companyId}/product-in-blbr/export`;
    const exportType = type ? `?type=${type}` : null;
    const exportStartDate = dateFilter && dateFilter.dateStart ? `&createdAfter=${dateFilter.dateStart}` : ``;
    const exportEndDate = dateFilter && dateFilter.dateEnd ? `&createdBefore=${dateFilter.dateEnd}` : ``;
    const productsIds = productsId && productsId.length > 0 ? `&productNames=${productsId}` : ``;
    const operation = operations ? `&typeOfOperation=${operations}` : ``;
    const advencedFilter = null;
    return this.httpClient.get<any>(req + exportType + exportStartDate + exportEndDate + productsIds + operation, {
      observe: 'response', params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });
  }
  exportExpencesStatistics(companyId: number, type, dateFilter, clientId, caisses, providers, filterType) {
    const req = this.companiesUrl + `/${companyId}/exports`;
    const exportType = type ? `?type=${type}` : null;
    const exportStartDate = dateFilter && dateFilter.dateStart ? `&createdAfter=${dateFilter.dateStart}` : ``;
    const exportEndDate = dateFilter && dateFilter.dateEnd ? `&createdBefore=${dateFilter.dateEnd}` : ``;
    const exportClientId = clientId && clientId.length > 0 ? `&clientIds=${clientId}` : ``;
    const exportCaisse = caisses && caisses.length > 0 ? `&cashDeskNames=${caisses}` : ``;
    const providerIds = providers && providers.length > 0 ? `&providerIds=${providers}` : ``;
    const interestedType = filterType && filterType.length > 0 ? `&interestedType=${filterType}` : ``;

    const advencedFilter = null;
    return this.httpClient.get<any>(req + exportType + exportStartDate + exportEndDate + exportClientId + exportCaisse + providerIds + interestedType,
      {
        observe: 'response', params: this.setParameters(advencedFilter),
        responseType: 'blob' as 'json'
      });
  }
  exportSAVStatistics(companyId: number, type, dateFilter, clientId, technicianIds, productIds) {
    const req = this.companiesUrl + `/${companyId}/sav-statistics/export`;
    const exportType = type ? `?type=${type}` : null;
    const exportStartDate = dateFilter && dateFilter.dateStart ? `&createdAfter=${dateFilter.dateStart}` : ``;
    const exportEndDate = dateFilter && dateFilter.dateEnd ? `&createdBefore=${dateFilter.dateEnd}` : ``;
    const exportClientId = clientId && clientId.length > 0 ? `&clientIds=${clientId}` : ``;
    const exportTechnicianIds = technicianIds && technicianIds.length > 0 ? `&technicianIds=${technicianIds}` : ``;
    const exportProviderIds = productIds && productIds.length > 0 ? `&productIds=${productIds}` : ``;
    // const interestedType = filterType && filterType.length > 0 ? `&interestedType=${filterType}` : ``;

    const advencedFilter = null;
    return this.httpClient.get<any>(req + exportType + exportStartDate + exportEndDate + exportClientId + exportTechnicianIds + exportProviderIds,
      {
        observe: 'response', params: this.setParameters(advencedFilter),
        responseType: 'blob' as 'json'
      });
  }
  exportPurchasesStatistics(companyId: number, type, dateFilter, productIds, providerIds, documentType) {
    const exportType = type ? `?type=${type}` : null;
    const req = this.companiesUrl + `/${companyId}/products-in-supplier-invoice/export`;
    const exportStartDate = dateFilter && dateFilter.dateStart ? `&createdAfter=${dateFilter.dateStart}` : ``;
    const exportEndDate = dateFilter && dateFilter.dateEnd ? `&createdBefore=${dateFilter.dateEnd}` : ``;
    const exportproviderIds = providerIds && providerIds.length > 0 ? `&providerIds=${providerIds}` : ``;
    const exportdocumentType = documentType ? `&documentType=${documentType}` : ``;
    const productsNames = productIds && productIds.length > 0 ? `&productNames=${productIds}` : ``;

    const advencedFilter = null;
    return this.httpClient.get<any>(req + exportType + exportStartDate + exportEndDate + productsNames + exportproviderIds + exportdocumentType,
      {
        observe: 'response', params: this.setParameters(advencedFilter),
        responseType: 'blob' as 'json'
      });
  }
  exportConvertionsStatistics(companyId: number, type: string, dateFilter: any, clientIds: number[], from: string, to: string) {
    const req = this.companiesUrl + `/${companyId}/convergence/export`;
    const exportType = type ? `?type=${type}` : '';
    const exportStartDate = dateFilter && dateFilter.dateStart ? `&createdAfter=${dateFilter.dateStart}` : '';
    const exportEndDate = dateFilter && dateFilter.dateEnd ? `&createdBefore=${dateFilter.dateEnd}` : '';
    const exportClientIds = clientIds && clientIds.length > 0 ? `&clientIds=${clientIds.join(',')}` : '';
    const exportFrom = from ? `&firstDocument=${from}` : '';
    const exportTo = to ? `&secondDocument=${to}` : '';
    const advencedFilter = null;
    return this.httpClient.get<any>(req + exportType + exportStartDate + exportEndDate + exportClientIds + exportFrom + exportTo, {
      observe: 'response',
      params: this.setParameters(advencedFilter),
      responseType: 'blob' as 'json'
    });}
}