import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';
import { MaterialModule } from './material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentsModule } from './components/documents/documents.module';

import { ImportPopupModule } from './components/popUps/import-popup/import-popup.module';
import { StartingNumberPopupModule } from './components/popUps/starting-number-popup/starting-number-popup.module';
import { SendByEmailPopupModule } from './components/popUps/send-by-email-popup/send-by-email-popup.module';
import { NotesPopupModule } from './components/popUps/notes/notes-popup.module';
import { CausePopupModule } from './components/popUps/cause/cause-popup.module';
import { SerialNumbersPopupModule } from './components/popUps/serial-numbers-popup/serial-numbers-popup.module';
import { NotInStockSerailNumbersPopupModule } from './components/popUps/not-in-stock-serail-numbers-popup/not-in-stock-serail-numbers-popup.module';
import { SerialNumbersErrorManagementPopupModule } from './components/popUps/serial-numbers-error-management/serial-numbers-error-management-popup.module';
import { SerialNumbersTrackingPopupModule } from './components/popUps/serial-numbers-tracking-popup/serial-numbers-tracking-popup.module';
import { FaultsSavPopupModule } from './components/popUps/faults-sav-popup/faults-sav-popup.module';
import { AddNotificationModule } from './components/popUps/add-notification/add-notification.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    FlexLayoutModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    DocumentsModule,
    ImportPopupModule,
    SerialNumbersPopupModule,
    SerialNumbersErrorManagementPopupModule,
    NotInStockSerailNumbersPopupModule,
    SerialNumbersTrackingPopupModule,
    AddNotificationModule
  ],
  exports: [
    TranslateModule,
    HttpClientModule,
    FlexLayoutModule,
    DocumentsModule,
  ],
  providers: [
    CookieService
  ]
})
export class SharedModule { }
