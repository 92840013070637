import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuantityValidationPopupComponent } from './quantity-validation-popup.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'app/shared/shared.module';

@NgModule({
  declarations: [QuantityValidationPopupComponent],
  imports: [
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    NgxDatatableModule,
    FuseSharedModule,
    MaterialModule,
    TranslateModule,
    SharedModule
  ],
  exports: [QuantityValidationPopupComponent]
})
export class QuantityValidationPopupModule { }
