import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FuseSearchBarModule, FuseShortcutsModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';
import { ToolbarComponent } from 'app/layout/components/toolbar/toolbar.component';
import { MaterialModule } from 'app/shared/material/material.module';
import { ContextSelectorComponent } from './context-selector/context-selector.component';
import { TranslateModule } from '@ngx-translate/core';
import { ContextOfflineComponent } from "./context-offline/context-offline.component";
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
    declarations: [
        ContextSelectorComponent,
        ToolbarComponent,
        ContextOfflineComponent

    ],
    imports: [
        RouterModule,
        MatButtonModule,
        MatIconModule,
        MatMenuModule,
        MatToolbarModule,
        MaterialModule,
        FuseSharedModule,
        FuseSearchBarModule,
        FuseShortcutsModule,
        TranslateModule,
        InfiniteScrollModule,
    ],
    exports: [
        ToolbarComponent,
    ]
})
export class ToolbarModule {
}
