import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { StorageUtils } from "app/shared/storage-utils";
import { environment } from "environments/environment";
import { Observable, from, of } from "rxjs";
import { FormControl, Validators } from '@angular/forms';
import { ValidatorsService } from './validators.service';
import { DataStorageService } from "./data.service";
import { catchError, switchMap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class UtilsService {
  activityUrl = environment.ENDPOINTS.ACIVITY_URL;
  communesUrl = environment.ENDPOINTS.COMMUNES_URL;
  wilayaUrl = environment.ENDPOINTS.WILAYAS_URL;
  legalFormsUrl = environment.ENDPOINTS.LEGAL_FORMS_URL;
  payementTermsUrl = environment.ENDPOINTS.PAYEMENT_TERMS_URL;
  mesureUnitUrl = environment.ENDPOINTS.MEASURE_URL;
  tvaUrl = environment.ENDPOINTS.TVA_URL;
  constructor(private httpClient: HttpClient,
    private validatorsService: ValidatorsService,
    private cacheStorageService: DataStorageService) { }

  getPayementTerms(): Observable<any> {
    const req = this.payementTermsUrl;
    // return this.httpClient.get<any>(req);
    if (!navigator.onLine) {
      return from(this.cacheStorageService.getItem(req)).pipe(
        catchError(() => this.httpClient.get<any>(req))
      );
    }
    return this.httpClient.get<any>(req).pipe(
      switchMap(response => {
        this.cacheStorageService.setItem(req, response);
        return of(response);
      })
    );
  }

  getAllWilaya(): Observable<any> {
    const req = this.wilayaUrl;
    // return this.httpClient.get<any>(req);
    if (!navigator.onLine) {
      return from(this.cacheStorageService.getItem(req)).pipe(
        catchError(() => this.httpClient.get<any>(req))
      );
    }
    return this.httpClient.get<any>(req).pipe(
      switchMap(response => {
        this.cacheStorageService.setItem(req, response);
        return of(response);
      })
    );
  }

  getAllCommunes(): Observable<any> {
    const req = this.communesUrl;
    // return this.httpClient.get<any>(req);
    if (!navigator.onLine) {
      return from(this.cacheStorageService.getItem(req)).pipe(
        catchError(() => this.httpClient.get<any>(req))
      );
    }
    return this.httpClient.get<any>(req).pipe(
      switchMap(response => {
        this.cacheStorageService.setItem(req, response);
        return of(response);
      })
    );
  }

  getAllLegalForms(): Observable<any> {
    const req = this.legalFormsUrl + `?size=2000&sort=id,asc`;
    // return this.httpClient.get<any>(req);
    if (!navigator.onLine) {
      return from(this.cacheStorageService.getItem(req)).pipe(
        catchError(() => this.httpClient.get<any>(req))
      );
    }
    return this.httpClient.get<any>(req).pipe(
      switchMap(response => {
        this.cacheStorageService.setItem(req, response);
        return of(response);
      })
    );
  }

  /**
   * get sectors activity
   */
  getActivities(): Observable<any> {
    const req = this.activityUrl;
    // return this.httpClient.get<any>(req);
    if (!navigator.onLine) {
      return from(this.cacheStorageService.getItem(req)).pipe(
        catchError(() => this.httpClient.get<any>(req))
      );
    }
    return this.httpClient.get<any>(req).pipe(
      switchMap(response => {
        this.cacheStorageService.setItem(req, response);
        return of(response);
      })
    );
  }

  /**
   * get sectors activity
   */
  getSectorsAcrivityGroup(
    page?: any,
    size?: any,
    search?: any,
    editFolder?
  ): Observable<any> {
    let request = null;
    const companyId = StorageUtils.getSelectedFolderId();

    const req = this.activityUrl + `?page=${page}&size=${size}`;
    const searchParam = search ? `&search=${search}` : "";

    if (editFolder) {
      request =
        req + searchParam + `&companyId=${companyId}&sort=companies.id,asc`;
    } else {
      request = req + searchParam;
    }
    // return this.httpClient.get<any>(request, { observe: "response" });

    if (!navigator.onLine) {
      return from(this.cacheStorageService.getItem(request)).pipe(
        catchError(() => this.httpClient.get<any>(request, { observe: "response" }))
      );
    }
    return this.httpClient.get<any>(request, { observe: "response" }).pipe(
      switchMap(response => {
        this.cacheStorageService.setItem(request, response);
        return of(response);
      })
    );
  }

  getMesureUnit() {
    const req = this.mesureUnitUrl + `?size=30`;
    // return this.httpClient.get<any>(req);
    if (!navigator.onLine) {
      return from(this.cacheStorageService.getItem(req)).pipe(
        catchError(() => this.httpClient.get<any>(req))
      );
    }
    return this.httpClient.get<any>(req).pipe(
      switchMap(response => {
        this.cacheStorageService.setItem(req, response);
        return of(response);
      })
    );
  }

  getTva() {
    // return this.httpClient.get<any>(this.tvaUrl);
    if (!navigator.onLine) {
      return from(this.cacheStorageService.getItem(this.tvaUrl)).pipe(
        catchError(() => this.httpClient.get<any>(this.tvaUrl))
      );
    }
    return this.httpClient.get<any>(this.tvaUrl).pipe(
      switchMap(response => {
        this.cacheStorageService.setItem(this.tvaUrl, response);
        return of(response);
      })
    );
  }
  //document with status draft(bl,quote,fa)
  checkDraftValidation(formGroup) {
    const invalid = [];
    const invalidProductControls = new Set();
    const controls = formGroup.controls;
    const productsControls = (formGroup.controls.productDTOS as any).controls;
    let setQuantityValidators = false;
    for (const name in controls) {
      if (controls[name].invalid) { invalid.push(name); }
    }
    if (invalid.length === 1 && invalid[0] === 'productDTOS') {
      productsControls.forEach(({ controls }) => {
        for (const name in controls) {
          if (controls[name].invalid) { invalidProductControls.add(name); }
        }
      });
      if (invalidProductControls.size === 1 && invalidProductControls.has('quantity')) { setQuantityValidators = true; }

    }
    return setQuantityValidators;
  }
}
