import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddNotificationComponent } from './add-notification.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    FuseSharedModule,
    MaterialModule,
    TranslateModule,
    InfiniteScrollModule,
  ],
  declarations: [AddNotificationComponent]
})
export class AddNotificationModule { }
