import { NgModule } from '@angular/core';
import { MaterialModule } from 'app/shared/material/material.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { FuseSharedModule } from '@fuse/shared.module';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { TranslateModule } from '@ngx-translate/core';
import { PopupSirh } from './popup-sirh.component';

@NgModule({
    declarations: [PopupSirh],
    imports: [
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule,
        NgxDatatableModule,
        FuseSharedModule,
        MaterialModule,
        TranslateModule,
    ],
    providers: [],
    exports: [PopupSirh]
})
export class PopupSirhModule { }
