import { FuseSidebarModule } from './../../../../@fuse/components/sidebar/sidebar.module';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from 'app/shared/material/material.module';
import { NgModule } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { AddProductTagComponent } from './add-product-tag.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [AddProductTagComponent],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    MaterialModule,
    MatInputModule,
    FuseSidebarModule,
    ReactiveFormsModule,
    TranslateModule,

  ],
  exports: [AddProductTagComponent]
})
export class AddProductTagModule { }
