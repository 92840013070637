import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { merge, Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { TranslateService } from '@ngx-translate/core';
import { NotifiersService } from 'app/services/notifiers.service';
import { Router } from '@angular/router';

@Component({
    selector: 'fuse-nav-vertical-group',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})
export class FuseNavVerticalGroupComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    classes = 'nav-group nav-item';

    @Input()
    item: FuseNavigationItem;
    showProgressBar: boolean = true;
    firstNavItem: boolean = true;

    networkStatus$: Subscription = Subscription.EMPTY;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        private translateService: TranslateService,
        private notifiersService: NotifiersService,
        private router: Router,
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();


        this.notifiersService.privilegesProgressNotifier$.subscribe(res => {
            this.showProgressBar = res;

        })

        this.notifiersService.changeContextNotifier$.subscribe(res => {
            
            this.firstNavItem = res !== "administration";


        })
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });
             // Subscribe to changeContextNotifier$
        this.notifiersService.changeContextNotifier$.subscribe(res => {
            this.firstNavItem = res !== "administration";
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

}