import { FuseNavigation } from '@fuse/types';
import { StorageUtils } from 'app/shared/storage-utils';


const folder = StorageUtils.getSelectedAccountId()
const ACCOUNT_PATH = 'client-account';
const SUBSCRIPTION_PATH = `client-account/${folder}/subscriptions`

export const adminNavigation: FuseNavigation[] = [
    {
        id: 'applications',
        title: 'ADMIN_NAV.CONTEXT',
        translate: 'ADMIN_NAV.CONTEXT',
        type: 'group',
        children: [
            {
                id: 'account',
                title: 'account',
                translate: 'ADMIN_NAV.ACCOUNT.TITLE',
                type: 'item',
                svgIcon: true,
                icon: 'user_account',
                color: '#0fd11b',
                url: 'view',
                parentRoute: ACCOUNT_PATH,
                
            },
            {
                id: 'admins',
                title: 'admins',
                translate: 'ADMIN_NAV.CLIENT_ADMINS.TITLE',
                type: 'item',
                icon: 'settings',
                color: '#ac25c3',
                svgIcon: true,
                url: 'admins',
                parentRoute: ACCOUNT_PATH
            },
            {
                id: 'users',
                title: 'users',
                translate: 'ADMIN_NAV.ACCOUNT_CLIENTS.TITLE',
                type: 'item',
                icon: 'users',
                color: '#495dcf',
                svgIcon: true,
                url: 'users',
                parentRoute: ACCOUNT_PATH
            },

            // {
            //     id: 'trackability',
            //     title: 'trackability',
            //     translate: 'ADMIN_NAV.TRACKS.TITLE',
            //     type: 'item',
            //     icon: 'history',
            //     color: '#ff7100',
            //     svgIcon: true,
            //     url: 'trackability',
            //     parentRoute: ACCOUNT_PATH
            // },
        ],
    },
];
