export const environment = {
    production: false,
    hmr: false,
    ENDPOINTS: {
        LOGIN_URL: '/auth/login',
        LOGOUT_URL: '/auth/logout',
        CREATE_PASSWORD_URL: '/uaa/api/activate',
        ACCOUNT_URL: '/uaa/api/account',
        ACCOUNTs_URL: '/uaa/api/my-client-accounts',
        CLIENT_ACCOUNT_URL: '/uaa/api/client-accounts',
        CSRF_URL: '/auth/hello',
        PUBLIC_ROUTE: '/public/api/platforms-urls/auth',
        IMAGE_URL: '/billing/api/attachments/',
        PROFIL_IMG_URL: '/uaa/api/account/profile-image',
        REGISTER_URL: 'uaa/api/register',
        GUEST_REGISTER_URL: 'uaa/api/register-guest',
        RECAPTCHA_KEY_URL: '/uaa/api/google-recaptcha-key-site',
        CHANGE_PASSWORD_URL: 'uaa/api/account/change-password',
        PRODUCTS_URL: "/billing/api/companies",
        MEASURE_URL: "/billing/api/measure-units",
        TVA_URL: "/billing/api/value-added-taxes",
        ATTECHMENT_URL: '/companymanagement/api/attachments',
        MY_CLIENT_ACCOUNTS_URL: '/uaa/api/my-client-accounts',
        FOLDER_URL: '/companymanagement/api/companies',
        LOGO_SET_IMG_URL: '/companymanagement/api/companies/logo',
        DISABLE_FOLDER_URL: '/companymanagement/api/companies',
        COMPANY_PERMISSION: '/uaa/api/company-permissions',
        NOTIFICATIONS_URL: '/uaa/api/companies',
        CLIENTS_URL: 'billing/api/companies',
        VERSION_URL: '/version.json',
        LEGAL_FORMS: 'billing/api/legal-forms',
        COMMUNES_URL: '/companymanagement/api/communes',
        WILAYAS_URL: '/companymanagement/api/wilayas',
        PRICES_URL: 'billing/api/companies',
        FAMILY_CLIENT_URL: '/billing/api/companies',
        LEGAL_FORMS_URL: '/companymanagement/api/legal-forms',
        ACIVITY_URL: '/companymanagement/api/activities',
        QUOTE_URL: '/billing/api/companies',
        PAYEMENT_TERMS_URL: '/billing/api/payment-terms',
        DELIVERY_NOTE_URL: '/billing/api/companies',
        INVOICE_URL: '/billing/api/companies',
        CLIENT_ADMINS_URL: '/uaa/api/client-accounts',
        CHECK_USER_URL: '/uaa/api/users/email',
        DASHBOARD_COUNT: '/billing/api/companies',
        ASSETS_URL: '/billing/api/companies',
        PROVIDERS_URL: '/billing/api/companies',
        COMPANIES_URL: '/billing/api/companies',
        WS: '/ws',
        WS_TOPIC: '/user/topic/msg',
        PRODUCTS_FAMILY_URL: "/billing/api/companies",
        BILLING_ATTACHEMENT_URL: "/billing/api/attachments",
        FUNDING_BOX_URL: "/billing/api/companies",
        INVENTORY_URL: "/billing/api/companies",
        TEAMS_URL: "/billing/api/companies",
        COUNTING_SHEET_URL: "/billing/api/companies",
        ASSOCIED_DOCS_URL: '/billing/api/companies',
        USERS_IMG_URL: '/uaa/api/users',
        PROCESS_URL: "/billing/api/companies",
        EXECUTION_URL: "/billing/api/companies",
        REPARATION_ORDER_URL: "/billing/api/companies",
        FAST_NOTIFICATIONS_URL: '/billing/api/companies',
    }
};