import { NgModule } from '@angular/core';
import { SIRHLoaderComponent } from './sirh-loader.component';


@NgModule({
    declarations: [SIRHLoaderComponent],
    imports: [],
    exports: [SIRHLoaderComponent]
})
export class SIRHLoaderModule {}
