import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
  {
    id: "applications",
    title: "FOLDER_NAV.CONTEXT",
    translate: "FOLDER_NAV.CONTEXT",
    type: "group",
    children: [
      {
        id: "DASHBOARD_FAST",
        title: "dashboard",
        translate: "FOLDER_NAV.DASHBOARD.TITLE",
        type: "item",
        icon: "dashboard-new",
        svgIcon: true,
        color: "#1f9cff",
        url: "dashboard",
        parentRoute: "folder",
        hidden: true,
      },
      {
        id: "folder",
        title: "folder",
        translate: "FOLDER_NAV.FOLDER.TITLE",
        type: "item",
        icon: "folder-new",
        color: "#ac25c3",
        svgIcon: true,
        url: "folder",
        parentRoute: "/folder",
        exactMatch: true,
        isParentRoute: true,
        hidden: false,
      },
      {
        id: "clients",
        title: "clients",
        translate: "FOLDER_NAV.CLIENTS.TITLE",
        type: "collapsable",
        icon: "customers-new",
        svgIcon: true,
        color: "#f54337",
        exactMatch: true,
        hidden: true,
        children: [
          {
            id: "CLIENT",
            title: "all-clients",
            translate: "FOLDER_NAV.ALL_CLIENTS.TITLE",
            type: "item",
            icon: "customers-new",
            svgIcon: true,
            url: "all-clients",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "CLIENT_FAMILY",
            title: "client-family",
            translate: "FOLDER_NAV.CLIENT_FAMILY.TITLE",
            type: "item",
            icon: "customers2-new",
            svgIcon: true,
            url: "all-clients-family",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
        ],
      },
      {
        id: "Products",
        title: "Products",
        translate: "FOLDER_NAV.PRODUCT.TITLE",
        type: "collapsable",
        icon: "product-new",
        svgIcon: true,
        color: "#FFCF00",
        exactMatch: true,
        hidden: true,
        children: [
          {
            id: "PRODUCT",
            title: "products",
            translate: "FOLDER_NAV.PRODUCT.TITLE",
            type: "item",
            icon: "product-new",
            svgIcon: true,
            url: "all-products",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "PRODUCT_FAMILY",
            title: "products-family",
            translate: "FOLDER_NAV.PRODUCT_FAMILY.TITLE",
            type: "item",
            icon: "product-group-new",
            svgIcon: true,
            url: "all-products-family",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "PRICE_LIST",
            title: "prices",
            translate: "FOLDER_NAV.PRICE_LIST.TITLE",
            type: "item",
            svgIcon: true,
            icon: "cost2-new",
            url: "all-prices-list",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
        ],
      },
      {
        id: "production",
        title: "production",
        translate: "FOLDER_NAV.PRODUCTION.TITLE",
        type: "collapsable",
        svgIcon: true,
        icon: "production_icon",
        color: "#646464",
        exactMatch: true,
        hidden: true,
        children: [
          {
            id: "PROCESS",
            title: "process",
            translate: "FOLDER_NAV.PROCESS.TITLE",
            type: "item",
            icon: "process_icon",
            svgIcon: true,
            url: "process",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "EXECUTION",
            title: "execution",
            translate: "FOLDER_NAV.EXECUTION.TITLE",
            type: "item",
            icon: "execution_icon",
            svgIcon: true,
            url: "execution",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
        ],
      },
      {
        id: "stock",
        title: "stock",
        translate: "FOLDER_NAV.STOCK.TITLE",
        type: "collapsable",
        icon: "stock-new",
        svgIcon: true,
        color: "#27c942",
        exactMatch: true,
        hidden: true,
        children: [
          {
            id: "ENTRY_VOUCHER",
            title: "entry-vouchers",
            translate: "FOLDER_NAV.ENTRY_VOUCHERS.TITLE",
            type: "item",
            svgIcon: true,
            icon: "cost2-new",
            url: "all-entry-vouchers",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "EXIT_VOUCHER",
            title: "exit-vouchers",
            translate: "FOLDER_NAV.EXIT_VOUCHERS.TITLE",
            type: "item",
            svgIcon: true,
            icon: "cost2-new",
            url: "all-exit-vouchers",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "TRANSFER_VOUCHER",
            title: "transfer-vouchers",
            translate: "FOLDER_NAV.TRANSFER_VOUCHERS.TITLE",
            type: "item",
            svgIcon: true,
            icon: "cost2-new",
            url: "all-transfer-vouchers",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "DEPOSIT",
            title: "deposits",
            translate: "FOLDER_NAV.DEPOSITS.TITLE",
            type: "item",
            svgIcon: true,
            icon: "product-new",
            url: "all-deposits",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "INVENTORY",
            title: "inventory",
            translate: "FOLDER_NAV.INVENTORY.TITLE",
            type: "item",
            svgIcon: true,
            icon: "cost2-new",
            url: "all-inventory",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
        ],
      },
      {
        id: "ventes",
        title: "Ventes",
        translate: "FOLDER_NAV.SALES.TITLE",
        type: "collapsable",
        icon: "sales-new",
        svgIcon: true,
        color: "#ff9800",
        exactMatch: true,
        hidden: true,
        children: [
          {
            id: "QUOTE",
            title: "all-quotes",
            translate: "FOLDER_NAV.QUOTE.TITLE",
            type: "item",
            icon: "devis-new",
            svgIcon: true,
            url: "all-quotes",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "PURCHASE_ORDER",
            title: "order-vouchers",
            translate: "FOLDER_NAV.ORDER_VOUCHERS.TITLE",
            type: "item",
            svgIcon: true,
            icon: "delivery-invoice-new",
            url: "order-vouchers",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "DELIVERY_NOTE",
            title: "all-delivery-notes",
            translate: "FOLDER_NAV.DELIVERY_NOTE.TITLE",
            type: "item",
            icon: "delivery-invoice-new",
            svgIcon: true,
            url: "all-delivery-notes",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "INVOICE",
            title: "all-invoices",
            translate: "FOLDER_NAV.BILLS.TITLE",
            type: "item",
            icon: "invoices-new",
            svgIcon: true,
            url: "all-invoices",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "RETURN_VOUCHER",
            title: "all-return-vouchers",
            translate: "FOLDER_NAV.RETURN_VOUCHER.TITLE",
            type: "item",
            icon: "return-new",
            svgIcon: true,
            url: "all-return-vouchers",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "CREDIT_NOTE",
            title: "all-assets",
            translate: "FOLDER_NAV.ASSETS.TITLE",
            type: "item",
            svgIcon: true,
            icon: "avoirs-new",
            url: "all-assets",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
        ],
      },

      {
        id: "opreations",
        title: "opreations",
        translate: "FOLDER_NAV.OPERATIONS.TITLE",
        type: "collapsable",
        svgIcon: true,
        icon: "operations_icon",
        color: "#00904B",
        exactMatch: true,
        hidden: true,
        children: [
          {
            id: "TECHNICIAN",
            title: "Technician",
            translate: "FOLDER_NAV.TECHNICIAN.TITLE",
            type: "item",
            icon: "technician_icon",
            svgIcon: true,
            url: "technicians",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "VEHICLE",
            title: "VEHICL",
            translate: "FOLDER_NAV.VEHICL.TITLE",
            type: "item",
            icon: "vahicle_icon",
            svgIcon: true,
            url: "vehicles",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "INTERVENTION",
            title: "INTERVENTION",
            translate: "FOLDER_NAV.INTERVENTION.TITLE",
            type: "item",
            icon: "intervention_icon",
            svgIcon: true,
            url: "interventions",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
        ],
      },
      {
        id: "SAV",
        title: "SAV",
        translate: "FOLDER_NAV.SAV.TITLE",
        type: "collapsable",
        svgIcon: true,
        icon: "sav_icon",
        color: "#00904B",
        exactMatch: true,
        hidden: true,
        children: [
          {
            id: "REPAIR_ORDER",
            title: "reparationOrder",
            translate: "FOLDER_NAV.REPARATION_ORDER.TITLE",
            type: "item",
            icon: "reparation_order_icon",
            svgIcon: true,
            url: "reparation-order",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "SAV_INTERVENTION",
            title: "savIntervention",
            translate: "FOLDER_NAV.SAV_INTERVENTION.TITLE",
            type: "item",
            icon: "intervention_icon",
            svgIcon: true,
            url: "sav-interventions",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "SAV_QUOTE",
            title: "savQuate",
            translate: "FOLDER_NAV.SAV_QUATE.TITLE",
            type: "item",
            icon: "devis-new",
            svgIcon: true,
            url: "sav-quotes",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "SAV_INVOICE",
            title: "savInvoice",
            translate: "FOLDER_NAV.SAV_INVOICE.TITLE",
            type: "item",
            icon: "invoices-new",
            svgIcon: true,
            url: "sav-invoices",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          // {
          //   id: "EXECUTION",
          //   title: "execution",
          //   translate: "FOLDER_NAV.EXECUTION.TITLE",
          //   type: "item",
          //   icon: "receipt_approved-new",
          //   svgIcon: true,
          //   url: "execution",
          //   exactMatch: true,
          //   parentRoute: "folder",
          //   hidden: true,
          // },
        ],
      },
      {
        id: "achats",
        title: "achats",
        translate: "FOLDER_NAV.PURCHASES.TITLE",
        type: "collapsable",
        icon: "buying-new",
        svgIcon: true,
        color: "#2485bd",
        exactMatch: true,
        hidden: true,
        children: [
          {
            id: "PROVIDER",
            title: "all-providers",
            translate: "FOLDER_NAV.PROVIDERS.TITLE",
            type: "item",
            svgIcon: true,
            icon: "supplier-new",
            url: "all-providers",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "SUPPLIER_PURCHASE_ORDER",
            title: "bdc-providers",
            translate: "FOLDER_NAV.BDC_PROVIDERS.TITLE",
            type: "item",
            svgIcon: true,
            icon: "supplier-new",
            url: "all-bdc-providers",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "RECEIPT_VOUCHER",
            title: "all-receipt-voucher",
            translate: "FOLDER_NAV.RECEIPT_VOUCHER.TITLE",
            type: "item",
            icon: "assignment_turned_in",
            url: "all-receipt-vouchers",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "SUPPLIER_INVOICE",
            title: "all-provider-invoices",
            type: "item",
            translate: "FOLDER_NAV.PROVIDER_INVOICES.TITLE",
            icon: "receipt",
            url: "all-provider-invoices",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "SUPPLIER_RETURN_ORDER",
            title: "all-return-provider",
            translate: "FOLDER_NAV.RETURN_PROVIDER.TITLE",
            type: "item",
            svgIcon: true,
            icon: "supplier-new",
            url: "all-return-provider",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
        ],
      },
      {
        id: "treasurership",
        title: "treasurership",
        translate: "FOLDER_NAV.TREASURERSHIP.TITLE",
        type: "collapsable",
        icon: "credit_card",
        color: "#009688",
        exactMatch: true,
        hidden: true,
        children: [
          {
            id: "CASH_RECEIPT",
            title: "all-cashings",
            translate: "FOLDER_NAV.CASHING.TITLE",
            type: "item",
            icon: "receipt_approved-new",
            svgIcon: true,
            url: "all-cashings",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "DISBURSEMENT",
            title: "all-disbursments",
            translate: "FOLDER_NAV.DISBURSMENTS.TITLE",
            type: "item",
            icon: "receipt_approved-new",
            svgIcon: true,
            url: "all-disbursments",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
          {
            id: "CASH_DESK",
            title: "all-boxs",
            translate: "FOLDER_NAV.FUNDINGBOX.TITLE",
            type: "item",
            icon: "receipt_approved-new",
            svgIcon: true,
            url: "all-boxs",
            exactMatch: true,
            parentRoute: "folder",
            hidden: true,
          },
        ],
      },
      {
        id: "STATISTICS",
        title: "statistics",
        translate: "FOLDER_NAV.STATISTICS.TITLE",
        type: "item",
        icon: "statistics",
        svgIcon: true,
        color: "#7e3300",
        url: "statistics",
        parentRoute: "folder",
        hidden: true,
      },
    ],
  },
];

export const allNavigation: FuseNavigation = {
  id: "applications",
  title: "FOLDER_NAV.CONTEXT",
  translate: "FOLDER_NAV.CONTEXT",
  type: "group",
  children: [
    {
      id: "DASHBOARD_FAST",
      title: "dashboard",
      translate: "FOLDER_NAV.DASHBOARD.TITLE",
      type: "item",
      icon: "dashboard-new",
      svgIcon: true,
      color: "#1f9cff",
      url: "dashboard",
      parentRoute: "folder",
      hidden: false,
    },
    {
      id: "folder",
      title: "folder",
      translate: "FOLDER_NAV.FOLDER.TITLE",
      type: "item",
      icon: "folder-new",
      color: "#ac25c3",
      svgIcon: true,
      url: "folder",
      parentRoute: "/folder",
      exactMatch: true,
      isParentRoute: true,
      hidden: false,
    },
    {
      id: "clients",
      title: "clients",
      translate: "FOLDER_NAV.CLIENTS.TITLE",
      type: "collapsable",
      icon: "customers-new",
      svgIcon: true,
      color: "#f54337",
      exactMatch: true,
      hidden: false,
      children: [
        {
          id: "CLIENT",
          title: "all-clients",
          translate: "FOLDER_NAV.ALL_CLIENTS.TITLE",
          type: "item",
          icon: "customers-new",
          svgIcon: true,
          url: "all-clients",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "CLIENT_FAMILY",
          title: "client-family",
          translate: "FOLDER_NAV.CLIENT_FAMILY.TITLE",
          type: "item",
          icon: "customers2-new",
          svgIcon: true,
          url: "all-clients-family",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
      ],
    },
    {
      id: "Products",
      title: "Products",
      translate: "FOLDER_NAV.PRODUCT.TITLE",
      type: "collapsable",
      icon: "product-new",
      svgIcon: true,
      color: "#FFCF00",
      exactMatch: true,
      hidden: false,
      children: [
        {
          id: "PRODUCT",
          title: "products",
          translate: "FOLDER_NAV.PRODUCT.TITLE",
          type: "item",
          icon: "product-new",
          svgIcon: true,
          url: "all-products",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "PRODUCT_FAMILY",
          title: "products-family",
          translate: "FOLDER_NAV.PRODUCT_FAMILY.TITLE",
          type: "item",
          icon: "product-group-new",
          svgIcon: true,
          url: "all-products-family",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "PRICE_LIST",
          title: "prices",
          translate: "FOLDER_NAV.PRICE_LIST.TITLE",
          type: "item",
          svgIcon: true,
          icon: "cost2-new",
          url: "all-prices-list",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
      ],
    },
    {
      id: "production",
      title: "production",
      translate: "FOLDER_NAV.PRODUCTION.TITLE",
      type: "collapsable",
      svgIcon: true,
      icon: "production_icon",
      color: "#646464",
      exactMatch: true,
      hidden: false,
      children: [
        {
          id: "PROCESS",
          title: "process",
          translate: "FOLDER_NAV.PROCESS.TITLE",
          type: "item",
          icon: "process_icon",
          svgIcon: true,
          url: "process",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "EXECUTION",
          title: "execution",
          translate: "FOLDER_NAV.EXECUTION.TITLE",
          type: "item",
          icon: "execution_icon",
          svgIcon: true,
          url: "execution",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
      ],
    },
    {
      id: "stock",
      title: "stock",
      translate: "FOLDER_NAV.STOCK.TITLE",
      type: "collapsable",
      icon: "stock-new",
      svgIcon: true,
      color: "#27c942",
      exactMatch: true,
      hidden: false,
      children: [
        {
          id: "ENTRY_VOUCHER",
          title: "entry-vouchers",
          translate: "FOLDER_NAV.ENTRY_VOUCHERS.TITLE",
          type: "item",
          svgIcon: true,
          icon: "cost2-new",
          url: "all-entry-vouchers",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "EXIT_VOUCHER",
          title: "exit-vouchers",
          translate: "FOLDER_NAV.EXIT_VOUCHERS.TITLE",
          type: "item",
          svgIcon: true,
          icon: "cost2-new",
          url: "all-exit-vouchers",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "TRANSFER_VOUCHER",
          title: "transfer-vouchers",
          translate: "FOLDER_NAV.TRANSFER_VOUCHERS.TITLE",
          type: "item",
          svgIcon: true,
          icon: "cost2-new",
          url: "all-transfer-vouchers",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "DEPOSIT",
          title: "deposits",
          translate: "FOLDER_NAV.DEPOSITS.TITLE",
          type: "item",
          svgIcon: true,
          icon: "product-new",
          url: "all-deposits",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "INVENTORY",
          title: "inventory",
          translate: "FOLDER_NAV.INVENTORY.TITLE",
          type: "item",
          svgIcon: true,
          icon: "cost2-new",
          url: "all-inventory",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
      ],
    },
    {
      id: "ventes",
      title: "Ventes",
      translate: "FOLDER_NAV.SALES.TITLE",
      type: "collapsable",
      icon: "sales-new",
      svgIcon: true,
      color: "#ff9800",
      exactMatch: true,
      hidden: false,
      children: [
        {
          id: "QUOTE",
          title: "all-quotes",
          translate: "FOLDER_NAV.QUOTE.TITLE",
          type: "item",
          icon: "devis-new",
          svgIcon: true,
          url: "all-quotes",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "PURCHASE_ORDER",
          title: "order-vouchers",
          translate: "FOLDER_NAV.ORDER_VOUCHERS.TITLE",
          type: "item",
          svgIcon: true,
          icon: "delivery-invoice-new",
          url: "order-vouchers",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "DELIVERY_NOTE",
          title: "all-delivery-notes",
          translate: "FOLDER_NAV.DELIVERY_NOTE.TITLE",
          type: "item",
          icon: "delivery-invoice-new",
          svgIcon: true,
          url: "all-delivery-notes",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "INVOICE",
          title: "all-invoices",
          translate: "FOLDER_NAV.BILLS.TITLE",
          type: "item",
          icon: "invoices-new",
          svgIcon: true,
          url: "all-invoices",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "RETURN_VOUCHER",
          title: "all-return-vouchers",
          translate: "FOLDER_NAV.RETURN_VOUCHER.TITLE",
          type: "item",
          icon: "return-new",
          svgIcon: true,
          url: "all-return-vouchers",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "CREDIT_NOTE",
          title: "all-assets",
          translate: "FOLDER_NAV.ASSETS.TITLE",
          type: "item",
          svgIcon: true,
          icon: "avoirs-new",
          url: "all-assets",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
      ],
    },
    {
      id: "opreations",
      title: "opreations",
      translate: "FOLDER_NAV.OPERATIONS.TITLE",
      type: "collapsable",
      svgIcon: true,
      icon: "operations_icon",
      color: "#00904B",
      exactMatch: true,
      hidden: false,
      children: [
        {
          id: "TECHNICIAN",
          title: "Technician",
          translate: "FOLDER_NAV.TECHNICIAN.TITLE",
          type: "item",
          icon: "technician_icon",
          svgIcon: true,
          url: "technicians",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "VEHICLE",
          title: "VEHICL",
          translate: "FOLDER_NAV.VEHICL.TITLE",
          type: "item",
          icon: "vahicle_icon",
          svgIcon: true,
          url: "vehicles",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "INTERVENTION",
          title: "INTERVENTION",
          translate: "FOLDER_NAV.INTERVENTION.TITLE",
          type: "item",
          icon: "intervention_icon",
          svgIcon: true,
          url: "interventions",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
      ],
    },
    {
      id: "SAV",
      title: "SAV",
      translate: "FOLDER_NAV.SAV.TITLE",
      type: "collapsable",
      svgIcon: true,
      icon: "sav_icon",
      color: "#00904B",
      exactMatch: true,
      hidden: false,
      children: [
        {
          id: "REPAIR_ORDER",
          title: "reparationOrder",
          translate: "FOLDER_NAV.REPARATION_ORDER.TITLE",
          type: "item",
          icon: "reparation_order_icon",
          svgIcon: true,
          url: "reparation-order",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "SAV_INTERVENTION",
          title: "savIntervention",
          translate: "FOLDER_NAV.SAV_INTERVENTION.TITLE",
          type: "item",
          icon: "intervention_icon",
          svgIcon: true,
          url: "sav-interventions",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "SAV_QUOTE",
          title: "savQuate",
          translate: "FOLDER_NAV.SAV_QUATE.TITLE",
          type: "item",
          icon: "devis-new",
          svgIcon: true,
          url: "sav-quotes",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "SAV_INVOICE",
          title: "savInvoice",
          translate: "FOLDER_NAV.SAV_INVOICE.TITLE",
          type: "item",
          icon: "invoices-new",
          svgIcon: true,
          url: "sav-invoices",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        // {
        //   id: "EXECUTION",
        //   title: "execution",
        //   translate: "FOLDER_NAV.EXECUTION.TITLE",
        //   type: "item",
        //   icon: "receipt_approved-new",
        //   svgIcon: true,
        //   url: "execution",
        //   exactMatch: true,
        //   parentRoute: "folder",
        //   hidden: true,
        // },
      ],
    },
    {
      id: "achats",
      title: "achats",
      translate: "FOLDER_NAV.PURCHASES.TITLE",
      type: "collapsable",
      icon: "buying-new",
      svgIcon: true,
      color: "#2485bd",
      exactMatch: true,
      hidden: false,
      children: [
        {
          id: "PROVIDER",
          title: "all-providers",
          translate: "FOLDER_NAV.PROVIDERS.TITLE",
          type: "item",
          svgIcon: true,
          icon: "supplier-new",
          url: "all-providers",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "SUPPLIER_PURCHASE_ORDER",
          title: "bdc-providers",
          translate: "FOLDER_NAV.BDC_PROVIDERS.TITLE",
          type: "item",
          svgIcon: true,
          icon: "supplier-new",
          url: "all-bdc-providers",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "RECEIPT_VOUCHER",
          title: "all-receipt-voucher",
          translate: "FOLDER_NAV.RECEIPT_VOUCHER.TITLE",
          type: "item",
          icon: "assignment_turned_in",
          url: "all-receipt-vouchers",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "SUPPLIER_INVOICE",
          title: "all-provider-invoices",
          type: "item",
          translate: "FOLDER_NAV.PROVIDER_INVOICES.TITLE",
          icon: "receipt",
          url: "all-provider-invoices",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "SUPPLIER_RETURN_ORDER",
          title: "all-return-provider",
          translate: "FOLDER_NAV.RETURN_PROVIDER.TITLE",
          type: "item",
          svgIcon: true,
          icon: "supplier-new",
          url: "all-return-provider",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
      ],
    },
    {
      id: "treasurership",
      title: "treasurership",
      translate: "FOLDER_NAV.TREASURERSHIP.TITLE",
      type: "collapsable",
      icon: "credit_card",
      color: "#009688",
      exactMatch: true,
      hidden: false,
      children: [
        {
          id: "CASH_RECEIPT",
          title: "all-cashings",
          translate: "FOLDER_NAV.CASHING.TITLE",
          type: "item",
          icon: "receipt_approved-new",
          svgIcon: true,
          url: "all-cashings",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "DISBURSEMENT",
          title: "all-disbursments",
          translate: "FOLDER_NAV.DISBURSMENTS.TITLE",
          type: "item",
          icon: "receipt_approved-new",
          svgIcon: true,
          url: "all-disbursments",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
        {
          id: "CASH_DESK",
          title: "all-boxs",
          translate: "FOLDER_NAV.FUNDINGBOX.TITLE",
          type: "item",
          icon: "receipt_approved-new",
          svgIcon: true,
          url: "all-boxs",
          exactMatch: true,
          parentRoute: "folder",
          hidden: false,
        },
      ],
    },
    {
      id: "STATISTICS",
      title: "statistics",
      translate: "FOLDER_NAV.STATISTICS.TITLE",
      type: "item",
      icon: "statistics",
      svgIcon: true,
      color: "#7e3300",
      url: "statistics",
      parentRoute: "folder",
      hidden: false,
    },
  ],
};