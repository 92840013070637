import { Injectable } from '@angular/core';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseNavigation } from '@fuse/types';
import { allNavigation, navigation } from 'app/navigation/navigation';
import { StorageUtils } from 'app/shared/storage-utils';
import { Subscription, fromEvent, merge, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotifiersService } from './notifiers.service';
import { SpinnerVisibilityService } from 'ng-http-loader';

@Injectable({
  providedIn: 'root'
})
export class PrivilegesService {
  privileges: any[];
  collapsableMenu = [
    'clients',
    'Products',
    'stock',
    'ventes',
    'achats',
    'treasurership',
    'production',
    'SAV',
    'opreations'
  ];
  notInOfflineNavItems = [
    'PROCESS',
    'EXECUTION',
    'ENTRY_VOUCHER',
    'EXIT_VOUCHER',
    'TRANSFER_VOUCHER',
    'DEPOSIT',
    'INVENTORY',
    'RETURN_VOUCHER',
    'CREDIT_NOTE',
    'TECHNICIAN',
    'VEHICLE',
    'INTERVENTION',
    'REPAIR_ORDER',
    'SAV_INTERVENTION',
    'SAV_QUOTE',
    'SAV_INVOICE',
    'PROVIDER',
    'SUPPLIER_PURCHASE_ORDER',
    'RECEIPT_VOUCHER',
    'SUPPLIER_INVOICE',
    'SUPPLIER_RETURN_ORDER',
    'CASH_DESK',
  ];

  notInOfflineCollapsableNavItems = [
    'production',
    'stock',
    'opreations',
    'SAV',
    'achats',
    'STATISTICS',
    'DASHBOARD_FAST',
  ]


  networkStatus: boolean = undefined;
  networkStatus$: Subscription = Subscription.EMPTY;

  constructor(
    private fuseNavigationService: FuseNavigationService,
    private notifiersService: NotifiersService,
    private spinnerService: SpinnerVisibilityService,
  ) {
    if (this.networkStatus$) {
      this.networkStatus$.unsubscribe();
    }
    this.checkNetworkStatus();
  }

  setPrivilegesByPermission(privilege) {
    this.privileges = StorageUtils.getPrivilege();
    const data = this.privileges.length > 0 ? this.privileges.find(element => element.module === privilege || element.module === "ALL") : null;
    return data;

  }

  updateNavigationSideBar() {
    this.notifiersService.privilegesProgressStatus(true);
    const clientAccount = StorageUtils.getClientAccount();
    const curentClientAccount = clientAccount.find(res => res.id === StorageUtils.getSelectedFolder()?.accountId);
    const fastActivation = curentClientAccount?.applicationProducts.find(res => res.applicationType === 'FAST') ? true : false;
    if (fastActivation) {
      this.privileges = StorageUtils.getPrivilege();
      if (this.privileges.length > 0) {
        this.privileges.forEach(privilege => {
          if (privilege.module === "ALL") {
            this.fuseNavigationService.updateNavigationItem('applications', allNavigation);
            this.notifiersService.privilegesProgressStatus(false);
          } else {
            this.updateNavigationItem(privilege.module);
          }
        });
      }
      if (this.privileges.length > 0 && this.privileges[0].module !== "ALL") {
        this.collapsableMenu.forEach(element => {
          this.updateNavigationCollapsableMenu(element)
        });
        this.notifiersService.privilegesProgressStatus(false);
      }
    } else {
      this.hideAllNavigationSidebarItems();
    }
  }

  updateNavigationItem(item) {
    const nav = this.fuseNavigationService.getNavigationItem(item);
    if (nav) {
      nav.hidden = false;
      this.fuseNavigationService.updateNavigationItem(item, nav);
    }
  }
  updateNavigationCollapsableMenu(item) {
    const nav = this.fuseNavigationService.getNavigationItem(item);
    const element = nav.children.find(elem => elem.hidden === false);
    if (element) {
      nav.hidden = false;
      this.fuseNavigationService.updateNavigationItem(item, nav);
    }

  }

  updateNavigationCollapsableMenuAfterUpdatingPrivileges(item) {
    const nav = this.fuseNavigationService.getNavigationItem(item);
    const element = nav.children.find(elem => elem.hidden === false);
    if (!element) {
      nav.hidden = true;
      this.fuseNavigationService.updateNavigationItem(item, nav);
    }

  }
  updateNavigationSideBarAfterUpdatingPrivileges() {
    const privileges = StorageUtils.getPrivilege();
    const principalItem = this.fuseNavigationService.getNavigation('main');
    const nav = this.fuseNavigationService.getFlatNavigation(principalItem);
    if (nav && nav.length > 0) {
      nav.forEach(element => {
        const singlePrivilege = privileges.find(priv => priv.module === element.id || priv.module === 'ALL');
        if (!singlePrivilege && element.id !== "folder" && element.hidden === false) {
          element.hidden = true;
          this.fuseNavigationService.updateNavigationItem(element.id, element);

        }
      });
      this.collapsableMenu.forEach(element => {
        this.updateNavigationCollapsableMenuAfterUpdatingPrivileges(element)
      });
    }
  }
  hideAllNavigationSidebarItems() {
    const nav = navigation
    nav[0].children.forEach(element => {
      element.hidden = true;
    });
    this.fuseNavigationService.updateNavigationItem('applications', nav);
    this.notifiersService.privilegesProgressStatus(false);

  }


  checkNetworkStatus() {
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        if (this.networkStatus !== undefined) {
          this.showHideOfflineItems(status, this.notInOfflineNavItems, 'items');
          this.showHideOfflineItems(status, this.notInOfflineCollapsableNavItems, 'collapsabe');
        }
        this.networkStatus = status;
        this.notifiersService.updateNetworkStatus(status);

      });
  }

  showHideOfflineItems(status, items, type) {
    this.spinnerService.show();
    const privileges = StorageUtils.getPrivilege();
    switch (type) {
      case 'items':
        items.forEach(item => {
          if (privileges[0]?.module === 'ALL' || privileges?.find(element => element.module === item)) {
            const nav = this.fuseNavigationService.getNavigationItem(item);
            if (nav) {
              nav.hidden = !status;
              this.fuseNavigationService.updateNavigationItem(item, nav);
            }
          }
        });
        this.spinnerService.hide();
        break;
      case 'collapsabe':
        items.forEach(item => {
          if (!status) {
            const nav = this.fuseNavigationService.getNavigationItem(item);
            if (nav) {
              nav.hidden = !status;
              this.fuseNavigationService.updateNavigationItem(item, nav);
            }
          } else {
            if (item !== 'STATISTICS' && item !== 'DASHBOARD_FAST') {
              this.updateNavigationCollapsableMenu(item);
            } else {
              if ((item !== 'STATISTICS' && privileges?.find(element => element.module === 'STATISTICS')) ||
                (item !== 'DASHBOARD_FAST' && privileges?.find(element => element.module === 'DASHBOARD_FAST')) ||
                privileges[0]?.module === 'ALL') {
                const nav = this.fuseNavigationService.getNavigationItem(item);
                if (nav) {
                  nav.hidden = false;
                  this.fuseNavigationService.updateNavigationItem(item, nav);
                }
              }
            }
          }

        });
        this.spinnerService.hide();
        break;

      default:
        break;
    }
  }


}
