import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FuseSharedModule } from '@fuse/shared.module';
import { MaterialModule } from 'app/shared/material/material.module';
import { TranslateModule } from '@ngx-translate/core';
import { NotInStockSerailNumbersPopupComponent } from './not-in-stock-serail-numbers-popup.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatCheckboxModule,
    MatIconModule,
    NgxDatatableModule,
    FuseSharedModule,
    MaterialModule,
    TranslateModule,
  ],
  declarations: [NotInStockSerailNumbersPopupComponent]
})
export class NotInStockSerailNumbersPopupModule { }
