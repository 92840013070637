import { StorageUtils } from 'app/shared/storage-utils';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,

} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';
import { FolderService } from 'app/services/folder.service';
import { PrivilegesService } from 'app/services/privileges.service';



@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    errorClass = 'error-snackbar';
    authLogin = '/auth/login';
    requestUrl = [this.authLogin, 'uaa/api/account', 'uaa/api/register', '/uaa/api/account/reset-password/init'];
    constructor(
        private translate: TranslateService,
        private router: Router,
        private authService: AuthService,
        private commonService: CommonService,
        private folderService: FolderService,
        private privilegesService: PrivilegesService,
    ) { }

    // tslint:disable-next-line: cognitive-complexity
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request)?.pipe(
            retry(0),
            catchError((error: HttpErrorResponse) => {
                if (!(error.error instanceof ErrorEvent)) {
                    // server-side error
                    switch (error.status) {
                        case 400:
                            if (error.error.errorKey != 'E704' && error.error.errorKey != 'E764' && error.error.errorKey != 'E766') {
                                if (error.error && error.error.errorKey) {
                                    this.commonService.showSnackBar('errors.' + error.error.errorKey, this.errorClass);
                                }
                                // else if (error.error.errorKey === 'E736') {
                                //     error.error.entityName = error.error.entityName.split('');
                                //     let index = error.error.entityName.indexOf('[');
                                //     error.error.entityName.splice(index, 1);
                                //     index = error.error.entityName.indexOf(']');
                                //     error.error.entityName.splice(index, 1);
                                //     error.error.entityName = error.error.entityName.join('');
                                //     console.log(error.error.entityName);
                                //     // TODO! : to change after add laguages
                                //     let duplicateProductError = this.translate.instant('errors.duplicateProductError')
                                //     this.commonService.showSnackBar(duplicateProductError + ' ' + error.error.entityName, this.commonService.errorMessage, true);
                                // } 
                                else {
                                    this.commonService.showSnackBar('errors.standardErrorMessage400', this.errorClass);
                                }
                            }

                            break;
                        case 401:
                            if (!request.url.includes(this.authLogin) && !request.url.includes('uaa/api/account')) {
                                StorageUtils.removeAuthToken();
                                StorageUtils.removeUser();
                                this.authService.setAuthenticated(false);
                                this.router.navigate(['/login']);

                            } else if (request.url.includes('/auth/login')) {
                                if (error.error && error.error.errorKey) {
                                    this.commonService.showSnackBar('errors.' + error.error.errorKey, this.errorClass);
                                } else {
                                    this.commonService.showSnackBar('errors.errorParams', this.errorClass);
                                }

                            }
                            break;
                        case 404:
                            const isImportPointing = request.url.split('/')[5] ? request.url.split('/')[5] === 'pointing-imports' : null;
                            if (!request.url.includes('/api/users/email')
                                && !request.url.includes('/uaa/api/account/reset-password/init') && !isImportPointing) {
                                this.commonService.showSnackBar('errors.standardErrorMessage404', this.errorClass);
                            }
                            break;
                        case 403:
                            const isLeaveRequest = request.url.split('/')[5] ? request.url.split('/')[5] === 'leave-requests' : null;
                            const isAbsenceRequest = request.url.split('/')[5] ? request.url.split('/')[5] === 'absences' : null;
                            const isQrCode = request.url.split('/')[5] ? request.url.split('/')[5].includes('qr-codes') : null;
                            const clientAccount = StorageUtils.getClientAccount();
                            if (!this.requestUrl.includes(request.url) && !isQrCode) {
                                this.commonService.showSnackBar('errors.standardErrorMessage403', this.errorClass);
                                if (!isLeaveRequest && !isAbsenceRequest && !isQrCode) {
                                    if (clientAccount && clientAccount.length > 0 && StorageUtils.getSelectedFolder()) {
                                        const curentClientAccount = clientAccount.find(res => res.id === StorageUtils.getSelectedFolder().accountId);
                                        const fastActivation = curentClientAccount.applicationProducts.find(res => res.applicationType === 'FAST') ? true : false;
                                        if (fastActivation) {
                                            this.router.navigate(['/folder', StorageUtils.getSelectedFolderId(), 'view']).then(() => {
                                                this.commonService.showSnackBar('errors.standardErrorMessagePrivileges403', this.errorClass);
                                                this.folderService.getCompanyPermissions(StorageUtils.getSelectedFolderId()).subscribe(res => {
                                                    StorageUtils.setPrivilege(res.modules);
                                                    this.privilegesService.updateNavigationSideBarAfterUpdatingPrivileges();
                                                })
                                            });
                                        } else {
                                            StorageUtils.removeSelectedFolderId();
                                            StorageUtils.removeSelectedFolder();
                                            this.router.navigate(['/profile']);
                                        }



                                    }
                                }
                            }
                            break;
                        case 500:
                            if (request.url.includes('/api/activate')) {
                                this.commonService.showSnackBar('errors.accountAlreadyActivated', this.errorClass);
                            } else {
                                this.commonService.showSnackBar('errors.standardErrorMessage500', this.errorClass);
                            }
                            break;
                        case 504:
                            this.commonService.showSnackBar('errors.standardErrorMessage504', this.errorClass);
                            break;
                        default:
                            break;
                    }
                    // this.spinner.hide();
                }
                return throwError(error);
            })
        );
    }
}
