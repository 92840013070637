
import { PopupSirh } from 'app/shared/components/popUps/popUp/popup-sirh.component';
import { PopupSirhModule } from 'app/shared/components/popUps/popUp/popup-sirh.module';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { fuseConfig } from 'app/fuse-config';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './services/auth.service';
import { ViewAllProductsComponent } from './main/pages/folder/products/view-all-products/view-all-products.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { SIRHLoaderModule } from './shared/components/sirh-loader/sirh-loader.module';
import { HttpErrorInterceptor } from './auth/http.interceptor';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CacheInterceptor } from './auth/cache.interceptor';
import { QuantityValidationPopupModule } from './shared/components/popUps/quantity-validation-popup/quantity-validation-popup.module';
import { PrintContingSheetPopupModule } from './shared/components/popUps/print-conting-sheet-popup/print-conting-sheet-popup.module';
import { CustoPrechargingModulesService } from './services/custo-precharging-modules.service';

const appRoutes: Routes = [

    {
        path: '',
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
        canActivateChild: [AuthGuard],
    },
    {
        path: 'login',
        component: AppComponent
    },
    {
        path: 'guest-register',
        component: AppComponent
    },
    {
        path: 'activate-account',
        component: AppComponent
    },

    {
        path: 'reset-password',
        component: AppComponent
    },
];
@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SweetAlert2Module,
        HttpClientModule,
        RouterModule.forRoot(appRoutes, { preloadingStrategy: CustoPrechargingModulesService }),
        TranslateModule.forRoot(),
        // Material moment date module
        MatMomentDateModule,
        // Material
        MatButtonModule,
        MatIconModule,
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        // App modules
        LayoutModule,
        PopupSirhModule,
        NgHttpLoaderModule.forRoot(),
        SIRHLoaderModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the application is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        QuantityValidationPopupModule,
        PrintContingSheetPopupModule
    ],
    bootstrap: [
        AppComponent
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: CacheInterceptor,
        //     multi: true
        // },
        AuthService,
    ]
})
export class AppModule {
}
